.layout.MuiGrid-container.bizz-card-layout {
  padding: 15px 0;
}

.bizz-category-dialog .MuiPaper-root {
  border: var(--border);
  border-radius: 12px;
  width: 500px;
  height: 600px;
}

.bizz-category-dialog .MuiDialogContent-dividers {
  border-bottom: 0;
  align-self: center;
  width: 200px;
  margin-top: -10px;
}

.bizz-category-dialog-content.MuiDialogContent-root {
  width: auto;
}

.bizz-category-dialog-content .MuiRadio-colorSecondary.Mui-checked, .bizz-category-dialog-content .MuiRadio-colorSecondary.Mui-checked ~ span {
  color: var(--primary-color);
}

.bizz-category-dialog-content .MuiRadio-colorSecondary ~ span {
  font-size: 24px;
  color: var(--title-color);
}

.bizz-color-dialog-content, .bizz-color-dialog-buttons.MuiDialogActions-root {
  display: flex;
  justify-content: center;  
}

.bizz-color-dialog-button.MuiButton-root  {
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  width: 150px;
  height: 40px;
  font-size: 25px;
  text-transform: none;
  padding-top: 10px;
  line-height: 0;
}

.bizz-color-dialog-button--first.MuiButton-root, .bizz-color-dialog-button--first.MuiButton-root:hover {
  background-color: var(--white-color);
  color: var(--secondary-color);
  border: var(--border);
}

.bizz-color-dialog-button--second.MuiButton-root, .bizz-color-dialog-button--second.MuiButton-root:hover {
    background-color: var(--primary-color);;
    color: var(--white-color);
}

.bizz-color-dialog-cropper .ReactCrop__crop-selection {
  width: 400px;
  height: 395.9px;
  border: 2px dotted var(--input-bgd-color);
}

.bizz-color-dialog-cropper .ReactCrop__crop-selection .ReactCrop__drag-handle{
  background-color: none;
	border: 0.1px solid rgb(255, 0, 0)
}

.bizz-color-dialog-cropper.ReactCrop {
  max-width: 80%;
}

.bizz-color-dialog-cropper.ReactCrop img {
  max-height: 371px;
}

.MuiGrid-root.settings-dialog-blank.settings-dialog-blank--half.settings-dialog-blank--bizz-card.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-1 {
  flex-grow: 0;
  max-width: calc(8.333333% / 3);
  flex-basis: calc(8.333333% / 3);
}
.MuiGrid-root.settings-dialog-blank.settings-dialog-blank--half.settings-dialog-blank--row-spacer.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-1 {
  flex-grow: 0;
  max-width: calc(8.333333% / 4);
  flex-basis: calc(8.333333% / 4);
}



.bizz-box-title.MuiTypography-root {
  font-size: var(--subtitle);
  color: var(--primary-color);
  margin-left: 5px;
}

.bizz-box-title.bizz-box-title--preview {
  margin: 0;
}

.bizz-box-title ~ span {
  font-size: var(--subtitle-small);
}

.bizz-wrapper-title span {
  align-self: center;
  color: var(--secondary-color);
  margin-left: 5px;
}

.bizz-wrapper-title svg {
  position: relative;
  color: var(--primary-color);
}

.bizz-wrapper-title {
  flex: 0 0 auto;
}

.bizz-main-info-box {
  border: var(--border);
  padding: 20px;
}

.bizz-preview-box {
  height: 245px;
  border: var(--border);
  background-color: var(--input-bgd-color);
}

.bizz-preview-box--preview {
  flex: 1 1 auto;
}

.bizz-merchant-contact-info-box {
  border: var(--border);
  padding: 20px;
}

.bizz-store-photos-box {
  flex: 1 1 auto;
  border: var(--border);
  padding: 20px;
}

.bizz-store-photos-dropzone {
  cursor: pointer;
}

.bizz-store-photos-dropzone div:first-child, .bizz-store-photos-dropzone div:first-child > div {
  height: 100%;
}

.bizz-merchant-textfield p {
  font-size: 20px;
  color: var(--primary-color);
}

.bizz-merchant-input .MuiInput-underline:before, .bizz-merchant-input .MuiInput-underline:after, .bizz-main-info-input .MuiInput-underline:before, .bizz-main-info-input .MuiInput-underline:after {
  border-bottom: 0 !important;
}

.bizz-merchant-input .MuiInputBase-root, .bizz-main-info-input .MuiInputBase-root {
  font-size: var(--subtitle-large);
  font-weight: 400;
  color: var(--title-color);
}

.bizz-merchant-input .MuiInputBase-input, .bizz-main-info-input .MuiInputBase-input {
  padding: 0;
}

.bizz-main-info-wrap.MuiGrid-container {
  margin-bottom: 20px;
}

.bizz-main-info-wrap:last-child .MuiGrid-container {
  margin: 0;
}

.bizz-main-info-wrap p:last-child span {
  font-size: 1rem;
}

.bizz-main-info-textfield {
  color: var(--primary-color);
  font-size: 20px;
  margin-bottom: -5px;
}

.bizz-main-info-textfield p {
  font-size: var(--subtitle);
  white-space: nowrap;
}

.bizz-main-info-category {
  color: var(--brown-gray-color);
  font-size: 14px;
}

.bizz-main-info-category--colored {
  color: var(--primary-color);
}

.bizz-main-info-category--action {
  margin-left: 5px;
}

.bizz-main-info-category-here {
  color: var(--primary-color);
  cursor: pointer;
}

.bizz-main-info-category--changer.MuiTypography-root {
  color: var(--title-color);
  font-size: var(--subtitle-large);
}

.bizz-main-info-logo-title-text.MuiTypography-body1 {
  color: var(--brown-gray-color);
  font-size: 14px;
  white-space: nowrap;
  margin-left: 5px;
}

.bizz-main-info-logo-title-text--pink.MuiTypography-body1 {
  color: var(--primary-color);
  font-size: 20px;
  margin: 0;
}

.bizz-main-info-logo-img {
  max-width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  background: rgba(var(--primary-color-rgb), 1);
  outline: var(--border);
  margin-top: 7px;
  cursor: pointer;
}

.bizz-location-wrapper.MuiGrid-item, .bizz-merchant-wrapper.MuiGrid-item {
  margin: 10px 0;
}

.bizz-location-box {
  flex: 1 1 auto;
  position: relative;
  height: 300px;
  border: var(--border);
}

.bizz-location-title-text {
  display: flex;
  align-items: center;
  color: var(--secondary-color);
  margin-left: 5px;
}

.bizz-distance-box {
  flex: 1 1 auto;
  border: var(--border);
  padding: 20px;
}

.bizz-distance-box p {
  align-items: center;
  font-size: var(--subtitle);
  color: var(--title-color);
}

.bizz-distance-input {
  flex: 1 1 0;
}

.bizz-distance-input .MuiFilledInput-root {
  width: 100px;
  border-radius: 12px;
  border: var(--border);
  padding: 10px;
  margin: 0 10px;
  
}

.bizz-distance-input .MuiFilledInput-underline:before, .bizz-distance-input .MuiFilledInput-underline:after, .bizz-distance-input .MuiFilledInput-underline:hover:before {
  border: 0px
}

.bizz-distance-input .MuiInputBase-input {
  color: var(--primary-color);
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 0;
}

.bizz-distance-input .MuiFilledInput-input {
  padding: 0;
}

.bizz-photos-topic {
  height: 100%;
  min-height: 255px;
  display: flex;
  justify-content: center;
  background: rgba(var(--primary-color-rgb), 1);
  overflow: hidden;
  cursor: pointer;
}

.bizz-photos-topic--middle {
  background: rgba(var(--primary-color-rgb), .6);
}

.bizz-photos-img {
  align-self: center;
  cursor: pointer;
}

.bizz-photos-img--avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.circle {
  position: absolute;
  color: var(--primary-color);
}
.wraper{
  position: absolute;
}

.number{
  position: absolute;
  width: 25px;
  height: 25px;
  font-size: 18px;
  color: var(--brown-gray-color);
  left:6px;
  top: 1px;
}

.bizz-button-wrapper.MuiGrid-container {
  flex: 0 0 auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: calc(8.333333% / 3) 1fr calc(8.333333% / 3) 1fr calc(8.333333% / 3);
}

.bizz-button-wrapper.MuiGrid-item {
  position: relative;
  margin: 20px 0;
}

.bizz-button-btn {
  height: 30px;
  border: var(--border);
}

.bizz-button-btn--cancel.MuiButton-outlinedPrimary, .bizz-button-btn--cancel.MuiButton-outlinedPrimary:hover {
  background: var(--white-color);
  color: var(--secondary-color);
  border: 1px solid  var(--secondary-color);
}

.bizz-button-btn--save.MuiButton-root {
  padding: 0;
}

.bizz-button-btn--save.MuiButton-containedPrimary {
  background: var(--primary-color);
  color: var(--white-color);
}

.bizz-button-btn--save.MuiButton-containedPrimary:hover {
  background: var(--primary-color);
  color: var(--white-color);
}

/* Dialogs */
.bizz-color-dialog .MuiDialog-paperWidthSm {
	max-width: 1000px;
	max-height: 500px;
	width: 1000px;
  height: 500px;
}

.bizz-category-dialog-title, .bizz-color-dialog-title {
  display: flex;
  justify-content: center;
  color: var(--title-strong-color);
  font-size: 20px;
}

.bizz-category-dialog-item {
  padding-bottom: 5px;
}

.bizz-category-dialog-item img {
  width: 40px;
  height: 40px;
  border: 1px solid var(--brown-gray-color);
}

.bizz-category-dialog-btn.MuiButton-outlinedPrimary, .bizz-category-dialog-btn.MuiButton-outlinedPrimary:hover {
  width: 150px;
  height: 40px;
  color: var(--secondary-color);
  border: var(--border);
  border-radius: 12px;
}

/* --------------- Settings for responsive --------------- */
@media screen and (max-width: 1650px) {
  .bizz-distance-box > div > div > .MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: var(--grid-xs-8);
    flex-basis: var(--grid-xs-8);
  }

  .bizz-distance-box > div > div > .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: var(--grid-xs-3);
    flex-basis: var(--grid-xs-3);
  }
}

@media screen and (max-width: 960px) {
  .bizz-card-layout .MuiGrid-root.settings-dialog-blank.settings-dialog-blank--half.settings-dialog-blank--bizz-card.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-1,
  .bizz-merchant-wrapper.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-grid-xs-2.MuiGrid-grid-sm-true,
  .MuiGrid-root.bizz-store-photos-dropzone.MuiGrid-item.MuiGrid-grid-xs-4,
  .bizz-distance-box > div > div > .MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: unset;
    flex-basis: unset;
  }

  .bizz-col.bizz-col--left.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-true > .MuiGrid-direction-xs-column,
  .bizz-merchant-wrapper.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-grid-xs-2.MuiGrid-grid-sm-true {
    flex-direction: unset;
  }

  .bizz-wrapper-title.MuiGrid-item:not(.bizz-wrapper-title--first) {
    margin-top: var(--okx-margin-small);
  }

  .bizz-photos-topic {
    max-height: 255px;
  }
  
  .bizz-store-photos-box.MuiGrid-container {
    flex-direction: column;
  }

  .bizz-distance-box > div > div > .MuiGrid-grid-xs-9 {
    /* margin-right: var(--okx-margin-tiny); */
  }

  .bizz-distance-box > div > div > .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: min-content;
    flex-basis: auto;
  }

  .bizz-location-wrapper.MuiGrid-item, .bizz-merchant-wrapper.MuiGrid-item {
    margin: 0;
  }

  .bizz-distance-box p {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .bizz-main-info-box.MuiGrid-root > .MuiGrid-item.MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: unset;
    flex-basis: unset;
  }

  .bizz-main-info-logo-wrapper.MuiGrid-justify-xs-center {
    justify-content: unset;
  }
 
  .bizz-distance-box > div > div > .MuiGrid-grid-xs-2, 
  .bizz-distance-box > div > div > .MuiGrid-grid-xs-1 {
    flex-grow: 0;
    max-width: var(--grid-xs-6);
    flex-basis: var(--grid-xs-6);
  }
}
/* --------------- End Settings for responsive --------------- */
.my-biiz-main-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}
