:root {
	--qrt-slice-hover-pos: 56.2%;
}

.main--dashboard {
	height: 100%;
	height: -webkit-fill-available;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dash-menu {
	width: 80%;
	max-width: 550px;
	min-width: 300px;
	margin: 20px auto;
	margin-top: 50px;
	position: relative;
}

.dash-menu::after {
	content: '';
	display: block;
	padding-top: 100%;
}

.cutout {
	width: 45%;
	height: 45%;
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	z-index: 5;
	transform: translate(-50%, -50%);
}
	
.circle-logo {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}
	
.circle-logo img {
	width: 70%;
	height: auto;
}

.quarter {
	position: absolute;
	width: 50%;
	height: 50%;
	transition: background-color .2s ease-out;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 3px solid transparent;
	margin: -9px;
	overflow: hidden;
}

.quarter:before {
	content: '';
	display: none;
	position: absolute;
	background-color: var(--circles-border-color);
	width: 92.2%;
	height: 92.2%;
	border-radius: 50%;
}

.quarter1:before {
	left: var(--qrt-slice-hover-pos);
	top: var(--qrt-slice-hover-pos);
}

.quarter2:before {
	right: var(--qrt-slice-hover-pos);
	top: var(--qrt-slice-hover-pos);
}

.quarter3:before {
	left: var(--qrt-slice-hover-pos);
	bottom: var(--qrt-slice-hover-pos);
}

.quarter4:before {
	right: var(--qrt-slice-hover-pos);
	bottom: var(--qrt-slice-hover-pos);
}

.quarter p {
	font-size: 2em;
	font-weight: 700;
}

.quarter:hover {
	border-color: var(--circles-border-color);
}

.quarter:hover:before {
	display: block;
}

.quarter1 {
	top: 0;
	left: 0;
	color: var(--primary-color);
	background-color: rgba(var(--primary-color-rgb), .2);
	border-radius: 100% 0 0 0;
}

.quarter2 {
	flex-direction: column;
	top: 0;
	right: 0;
	color: var(--primary-color);
	background-color: rgba(var(--primary-color-rgb), .4);
	border-radius: 0 100% 0 0;
}

.quarter3 {
	bottom: 0;
	left: 0;
	color: var(--white-color);
	background-color: rgba(var(--primary-color-rgb), 1);
	border-radius: 0 0 0 100%;
}

.quarter4 {
	bottom: 0;
	right: 0;
	color: var(--white-color);
	background-color: rgba(var(--primary-color-rgb), .6);
	border-radius: 0 0 100% 0;
}

.notification-circle {
	width: 18%;
	height: 18%;
	box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.16);
	border: 5px solid #f5fff5;
	background-color: var(--circles-border-color);
	position: absolute;
	z-index: 3;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 57%;
	right: -11%;
}

.notification-circle span {
	padding-top: 15%;
	font-size: 5em;
	font-weight: 700;
	color: var(--white-color);
}

.dash-menu .quarter:hover + .desc {
	opacity: 1;
}

/* hover text */

.dash-menu .desc {
	position: absolute;
	color: var(--secondary-color);
	width: 50%;
	height: 50%;
	opacity: 0;
	transition: .2 ease-out;
	pointer-events: none;
	display: flex;
	flex-direction: column;
}

.dash-menu .desc p {
	font-size: 2.4rem;
	white-space: nowrap;
	margin: 0;
}

.icons {
	display: flex;
	justify-content: flex-start;
}

.icons svg {
	font-size: 3rem;
}

.desc--promos, .desc--messages {
	text-align: right;
}

.desc--promos .icons, .desc--messages .icons {
	justify-content: flex-end;
}

.dash-menu .quarter1 + .desc, .dash-menu .quarter3 + .desc {
	left: -300px;
}

.dash-menu .quarter1 + .desc, .dash-menu .quarter2 + .desc {
	top: 0%;
	justify-content: flex-end;
}

.dash-menu .quarter2 + .desc, .dash-menu .quarter4 + .desc {
	right: -300px;
}

.dash-menu .quarter3 + .desc, .dash-menu .quarter4 + .desc {
	bottom: 0%;
}

.desc.desc--bizz, .desc.desc--promos {
	margin-bottom: 20px;
}

.desc.desc--settings, .desc.desc--messages {
	margin-top: 20px;
}

/* end hover text */

@media screen and (max-width: 1200px) {
	.dash-menu .desc p {
		font-size: 2rem;
	}
	.icons svg {
		font-size: 3rem;
	}
	.dash-menu .quarter1 + .desc, .dash-menu .quarter2 + .desc {
		justify-content: flex-start;
	}
	.dash-menu .quarter3 + .desc, .dash-menu .quarter4 + .desc {
		justify-content: flex-end;
	}
	.dash-menu .quarter1 + .desc, .dash-menu .quarter3 + .desc {
		left: -200px;
	}
	.dash-menu .quarter2 + .desc, .dash-menu .quarter4 + .desc {
		right: -200px;
	}
}

@media screen and (max-width: 1020px) {
	.dash-menu .desc p {
		font-size: 1.5rem;
	}
	.icons svg {
		font-size: 2rem;
	}
	.dash-menu .quarter1 + .desc, .dash-menu .quarter3 + .desc {
		left: -100px;
	}
	.dash-menu .quarter2 + .desc, .dash-menu .quarter4 + .desc {
		right: -100px;
	}
}

@media screen and (max-width: 820px) {
	.dash-menu .desc p {
		font-size: 1.2rem;
	}
	.icons svg {
		font-size: 1.6rem;
	}
	.dash-menu .quarter1 + .desc, .dash-menu .quarter3 + .desc {
		left: -50px;
	}
	.dash-menu .quarter2 + .desc, .dash-menu .quarter4 + .desc {
		right: -50px;
	}
}

@media screen and (max-width: 720px) {
	:root {
		--qrt-slice-hover-pos: 56.5%;
	}
	.dash-menu {
		margin-top: 40px;
	}
	.quarter p {
		font-size: 1.5em;
	}
	.notification-circle {
		border-width: 4px;
	}
	.notification-circle span {
		font-size: 4em;
	}
	.dash-menu {
		margin-top: 60px;
	}
	.dash-menu .desc {
		width: auto;
		height: auto;
		flex-direction: row;
	}
	.dash-menu .desc br {
		display: none;
	}
	.dash-menu .quarter1 + .desc, .dash-menu .quarter3 + .desc,
	.dash-menu .quarter2 + .desc, .dash-menu .quarter4 + .desc {
		left: 0;
		right: 0;
		top: -50px;
		bottom: auto;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
	.desc.desc--bizz, .desc.desc--promos,
	.desc.desc--settings, .desc.desc--messages {
		margin: 0;
	}
}

@media screen and (max-width: 580px) {
	:root {
		--qrt-slice-hover-pos: 57.5%;
	}
	.dash-menu {
		margin-top: 30px;
	}
	.quarter p {
		font-size: 1.2em;
	}
	.notification-circle {
		border-width: 3px;
	}
	.notification-circle span {
		font-size: 3em;
	}
	.dash-menu {
		margin-top: 40px;
	}
	.dash-menu .quarter1 + .desc, .dash-menu .quarter3 + .desc,
	.dash-menu .quarter2 + .desc, .dash-menu .quarter4 + .desc {
		top: auto;
		bottom: -50px;
	}
}

@media screen and (max-width: 490px) {
	:root {
		--qrt-slice-hover-pos: 58%;
	}
}
