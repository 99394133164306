.promotions-no-access {
	position: relative;
	display: flex;
	height: 300px;
	border: var(--border);
	align-items: center;
  justify-content: center;
	color: red;
  font-size: 20px;
}
