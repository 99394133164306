.header.MuiGrid-container {
  flex: 0 0 auto;
  height: var(--height-of-header);
  background-color: '#fff';
  color:  var(--title-color);
  padding: 0 20px;
  border-bottom: var(--border-layout);
}

.header--none.MuiGrid-container {
  display: none;
}

.header-logo {
	width: 50px;
	height: auto;
	margin: 0 10px 0 0;
  }

.header-aside-logo {
  cursor: pointer;
}

.header-aside-logo p {
  font-size: 19px;
}

.header-aside-logo-cirlce {
  position: relative;
  width: 35px;
  height: 35px;
  margin: 0 auto;
  margin-top: 5px;
}

.header-text {
  display: contents;
  font-size: 40px;
  color: var(--title-color);
  font-weight: 500;
  white-space: nowrap;
  margin: 0;
}

.header-aside-quarter {
  position: absolute;
  width: 50%;
  height: 50%;
  transition: .2s ease-out;
  z-index: 1;
}

.header-aside-quarter--1 {
  top: -0.5px;
  left: -0.5px;
  background-color: rgba(var(--primary-color-rgb), .2);
  border-radius: 100% 0 0 0;
}
.header-aside-quarter--2 {
  top: -0.5px;
  right: -0.5px;
  background-color: rgba(var(--primary-color-rgb), .4);
  border-radius: 0 100% 0 0;
}

.header-aside-quarter-badge {
  position: absolute;
  background: var(--circles-border-color);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: #fff;
  top: -5px;
  right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .6rem;
  border: 2px solid var(--white-color);
  z-index: 3;
}

.header-aside-quarter--3 {
  bottom: -0.5px;
  left: -0.5px;
  background-color: rgba(var(--primary-color-rgb), 1);
  border-radius: 0 0 0 100%;
}
.header-aside-quarter--4 {
  bottom: -0.5px;
  right: -0.5px;
  background-color: rgba(var(--primary-color-rgb), .6);
  border-radius: 0 0 100% 0;
}
.header-aside-cutout {
  width: 35%;
  height: 35%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 2;
}


.header-title-aside  {
  font-size: 1.6em;
  color: var(--primary-color);
  margin: 0 10px 0 20px;
  cursor: default;
}

.header-aside-icon svg {
  font-size: 2em;
  color: var(--secondary-color);
}

.header-text.MuiTypography-body1  {
  font-size: var(--header-text);
  text-transform: capitalize;
  white-space: nowrap;
}

.header-back-btn {
  height: 31.5px;
  width: 32.4px;
  display: flex;
  padding-left: 20px;
  color: rgb(150, 150, 150);
  cursor: pointer;
}

/* .title-bizz {
  font-size: 2em;
  margin: 0;
}


.text {
  font-size: 40px;
  color: var(--title-color);
  margin: 0;
  font-stretch: normal;
  font-weight: normal;
  line-height: 1.4; 
}

.title{
  font-family: AvenirLTStd-Roman;
  font-size: 40px;
  line-height: 1.4;
  color: var(--title-color);
} */

.header-logout > div {
  width: auto;
  height: auto;
  cursor: pointer;
}

.header-logout img {
  width: 28px;
  height: auto; 
  margin-right: 10px;
}

.header-logout-text {
  align-self: flex-end;
  color: #b4b4b4;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
	.header-title-aside, .header-logout-text  {
		font-size: 1.4em;
	}
	.header-aside-icon svg {
		font-size: 1.8em;
	}
}

@media screen and (max-width: 960px) {
	.header-title-aside, .header-logout-text  {
		font-size: 1.2em;
	}
	.header-aside-icon svg {
		font-size: 1.6em;
	}
	.header-aside-logo-cirlce {
		width: 30px;
		height: 30px;
	}
	.header-aside-logo p {
		font-size: 16px;
	}
	.header-logout img {
		width: 25px;
	}
}

@media screen and (max-width: 840px) {
	.header-title-aside, .header-logout-text  {
		font-size: 1em;
	}
	.header-aside-icon svg {
		font-size: 1.5em;
	}
	.header-aside-logo-cirlce {
		width: 25px;
		height: 25px;
	}
	.header-aside-logo p {
		font-size: 14px;
	}
	.header-logout img {
		width: 22px;
	}
}

@media screen and (max-width: 600px) {
  .header-text.MuiTypography-body1  {
    font-size: 1.8rem;
  }
}
