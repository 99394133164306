.preview-promotion-bordered--padding {
	padding: 5px 5px 0 5px;
}

.preview-promotion-item-list.MuiGrid-item {
	position: relative;
	flex: 0 0 auto;
	height: 50px;
	padding: 0 10px 0 0;
	margin: 5px 0;
	background: var(--white-color);
	border: var(--border);
	transition: border .2s ease-out;
}

.preview-promotion-item-list.preview-promotion-item-list--active.MuiGrid-item {
	border: var(--border-thicker);
}

.preview-promotion-item-list--visible {
	height: 0;
	visibility: visible;
	opacity: 1;
	pointer-events: auto;
}

.preview-promotion-item-list.preview-promotion-item-list--hidden {
	/* height: 200px; */
	/* visibility: hidden;
	opacity: 1; */
	pointer-events: none;
	border: 0;
}

.preview-promotion-item-list.preview-promotion-item-list--disabled, 
.preview-promotion-item-list.preview-promotion-item-list--disabled .preview-promotion-item-list-icons {
	pointer-events: none;
}

.preview-promotion-item-list.preview-promotion-item-list--disabled .preview-promotion-item-list-icons > svg {
	pointer-events: auto;
}

.preview-promotion-item-list.preview-promotion-item-list--undisabled {
	pointer-events: auto;
}

.preview-promotion-item-list:first-child {
	margin-top: 0;
}

.preview-promotion-item-list:last-child {
	margin-bottom: 0;
}

.preview-promotion-item-list-txt {
	font-family: AvenirLTStd-Roman;
	font-size: 20px;
	color: var(--title-color);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.preview-promotion-item-list-logo img {
	width: 25px;
	height: 37px;
	border: var(--border);
	object-fit: cover;
}

.preview-promotion-item-list-icons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: var(--title-color);
}

.preview-promotion-item-list-icons svg {
	cursor: pointer;
	pointer-events: auto;
}

.preview-promotion-item-second-list-view {
	position: absolute;
	flex: 0 0 auto;
	height: 200px;
	background-color: var(--white-color);
	border: var(--border-thicker);
	padding: 10px;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: .3s ease-out;
	z-index: 6;
}

.preview-promotion-item-second-list-view .preview-promotion-item-title {
	margin-top: 30px;
}

.preview-promotion-item-second-list-view .preview-promotion-item-second-list-img {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.preview-promotion-item-second-list-view--visible {
	visibility: visible;
	opacity: 1;
	pointer-events: auto;
}

.preview-promotion-item-second-list-view--hidden {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

.preview-promotion-item-second-list-view-title.MuiTypography-body1, .preview-promotion-item-second-list-view-price.MuiTypography-body1, .preview-promotion-item-second-list-view-desc.MuiTypography-body1 {
	display: flex;
	justify-content: center;
	font-size: 25px;
	line-height: 1;
	color: var(--title-color);
}

.preview-promotion-item-second-list-view-price.MuiTypography-body1 {
	position: relative;
	font-size: 30px;
}

.preview-promotion-item-second-list-view-price p {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
}

.preview-promotion-item-second-list-view-price span {
	margin-left: 7px;
	text-transform: uppercase;
}

.preview-promotion-item-second-list-view-desc.MuiTypography-body1 {
	font-size: 20px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 20px;
}

.preview-promotion-item-second-list-small-topic {
	width: 60px;
	height: 90px;
	display: flex;
	border: var(--border);
}

.preview-promotion-item-photos-img--avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-promotion-item-second-list-small-topic--middle {
	border-right: 0;
	border-left: 0;
}

.preview-promotion-item-second-list-img img {
	width: 90px;
	height: 90px;
	border: var(--border);
}

.preview-promotion-item-second-list-view-pics-grid {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
}

.preview-promotion-item-second-list-view-pics-grid {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
}

.preview-promotion-bordered {
	flex: 1 1 auto;
	background: var(--input-bgd-color);
	border: var(--border);
	padding: 7px 0 0 0;
}

.preview-promotion-item--start {
	flex: 0 0 auto;
	min-height: 200px;
	background: var(--white-color);
	border: var(--border);
	padding: 10px;
}

.preview-promotion-item--middle.MuiGrid-container {
	flex: 1 1 auto;
	display: block;
	width: 100%;
	height: auto;
	/* min-height: 350px;
	max-height: calc(100vh - 460px); */
	padding: 5px 0;
	overflow: hidden;
	overflow-y: auto;
}

.preview-promotion-item--date.MuiGrid-container {
	display: block;
	text-align: center;
	margin-bottom: 10px;
}

.preview-promotion-item.MuiGrid-container {
	width: calc(100% - 10px);
}

.preview-promotion-item-holder {
	height: 100px;
}

.preview-promotion-item-img img {
	border: var(--border);
	border-radius: 50%;
}

.preview-promotion-item-img--left img {
	width: 54px;
	height: 54px;
}

.preview-promotion-item-img--right img {
	width: 42px;
	height: 42px;
}

.preview-promotion-item-txt p {
	font-size: 25px;
	text-align: center;
	/* white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden; */
	color: var(--title-color);
}

.preview-promotion-item-title,
.preview-promotion-item-label {
	word-break: break-all;
}

.preview-promotion-item-date p {
	font-size: 15px;
	color: var(--title-color);
}

.preview-promotion-item--button {
	flex: 0 0 auto;
	width: 100%;
	height: 50px;
}

.preview-promotion-item--button.MuiGrid-container {
	width: 100%;
	margin: 0;
}

.preview-promotion-item--button button {
	width: 100%;
	background: var(--white-color);
	color: var(--primary-color);
	border: var(--border-thicker);
	border-radius: var(--border-radius-button);
	font-size: 20px;
	font-weight: normal;
	text-transform: capitalize;
}

.preview-promotion-item--button button.MuiButton-outlined {
	padding: 0;
}

.preview-promotion-item--button button:hover {
	background: var(--white-color);
}

.preview-promotion-item--button svg {
	font-size: 1.25em;
}

.promotion-main-buttons-dialog.MuiButton-containedPrimary, .promotion-main-buttons-dialog.MuiButton-containedPrimary:hover {
	height: 40px;
	font-size: 25px;
}

.promotion-main-outlined-buttons.MuiButton-outlined {
	display: flex;
	color: #b4b4b4;
  flex-direction: column;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);
	border-radius: 12px;
	width: 150px;
  font-size: 25px;
	height: 40px;
  text-transform: none;
  margin: 5px;
	border: var(--border);
}

.preview-promotions-dialog .MuiDialog-paperWidthSm {
  border-radius: 10px;
	background-color: var(--white-color);
	align-items: center;
	border: var(--border);
	width: 700px;
  height: 500px;
	box-shadow: var(--box-shadow);
}

.preview-promotions-dialog-text .MuiTypography-h6 {
	color: var(--title-strong-color);
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.preview-promotions-dialog-content.MuiDialogContent-root {
  width: 450px;
  height: 350px;
	border: var(--border);
	background-color: var(--input-bgd-color);
}

.preview-promotions-dialog-content-text .MuiGrid-grid-xs-12, .preview-promotions-dialog-content-text .MuiTypography-body1 {
  color: #646464;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
	cursor: pointer;
}

.preview-promotions-dialog-content-text--color .MuiTypography-body1{
	  color: var(--primary-color)
}