.statistics-for.MuiTypography-body1 {
	margin-top: 20px;
	font-size: 25px;
	color: var(--title-strong-color);
}

.MuiGrid-container.statistics-days {
	flex-basis: auto;
	margin-top: 5px;
}

.MuiGrid-container.statistics-days .statistics-days-formcontrol {
	width: 100%;
}

.MuiFormGroup-root.statistics-days-radiogroup {
	width: 100%;
	flex-direction: row;
}

.MuiGrid-item.statistics-day {
	display: flex;
	justify-content: center;
}

.settings-wrap-box.statistics-wrap-box {
  flex-wrap: nowrap;
  flex: 0 1 auto;
}

.settings-wrap-box.statistics-wrap-box > .settings-item {
	padding: 30px 30px 30px 80px;
}

.settings-container.statistics-container {
	flex-wrap: nowrap;
	flex-direction: column;
}

.settings-wrap-of-item.statistics-wrap-of-item {
	flex-wrap: nowrap;
}

.settings-wrap-of-item.statistics-wrap-of-item span {
	font-size: 25px;
}

.statistics-qr-icons {
	display: flex;
	align-items: center;
	flex-direction: row;
}

.statistics-qr-icons.reverse {
	flex-direction: row-reverse;
	margin-right: 5px;
}

.statistics-qr-icons svg:nth-child(2) {
	padding-top: 5px;
	padding-left: 5px;
}

.statistics-qr-icons.reverse svg {
	padding-left: 0;
}

.statistics-qr-icons.reverse svg:nth-child(1) {
	margin-left: -10px!important;
}

.statistics-qr-label.last {
	margin-left: 10px!important;
}

.pie-chart {
	display: flex;
	justify-content: center;
	flex: 1 0 auto;
	align-items: center;
}

.pie-chart .apexcharts-legend {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center!important;
}

/* .pie-chart .apexcharts-legend > .apexcharts-legend-series .apexcharts-legend-marker {
	border-radius: 0;
	height: 30px;
	width: 30px;
} */

.settings-dialog-text.pie-chart-dialog-title .MuiTypography-h6 {
	width: 80%;
	margin: 0 auto;
}

.settings-dialog-text.pie-chart-dialog-title .MuiTypography-h6 .settings-dialog-title-divider.MuiDivider-root {
	width: 60%;
	margin: 0 auto;
}

.pie-chart-dialog .MuiDialog-paperWidthLg {
	height: 60%;
	width: 70vw;
	border-radius: 10px;
	border: 1px solid var(--primary-color);
}

.MuiButton-root.pie-chart-close {
	width: auto; 
	width: 150px;
	margin: 0 auto 20px auto;
	padding: 10px;
}