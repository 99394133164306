.code-layout {
  display: flex;
  align-items: center;
}

.code-wrapper {
  color: var(--primary-color);
  margin-bottom: 40px;
}

.code-title.MuiTypography-body1, .code-number.MuiTypography-body1 {
  font-size: 30px;
}

.code-wrapper--textfield.MuiGrid-item {
  padding: 0;
  margin: 40px 0 0 0;
}

.code-wrapper--textfield > .MuiFormControl-root {
  margin: 0;
}

.code-wrapper-button--first.MuiButton-root  {
  margin: 20px 0;
}

.code-number.MuiTypography-body1 {
  color: var(--title-color);
  margin-top: 10px;
}

.code-input.MuiFormControl-root {
  border: var(--border);
  border-radius: 0;
}

.code-input .MuiFilledInput-inputMarginDense {
  height: 10px;
  color: var(--title-color);
  border: 2px solid var(--primary-color);
}
