.settings-email-verification-content-main.MuiDialogContent-root {
	border: var(--border);
	border-radius: var(--border-radius-button);
	width: 600px;
  height: 200px;
	box-shadow: var(--box-shadow);
	background-color: var(--white-color);
	padding-top: 0;
	margin-bottom: 20px;
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.setting-email-verification-buttons--width {
  width: 400px;
  height: 40px;
}

.setting-email-verification-buttons--width.MuiButton-contained.Mui-disabled {
	background-color: rgba(var(--primary-color-rgb), .5);
	color: var(--white-color);
}

.settings-email-verification-inputs.MuiFormControl-fullWidth {
	width: 50%;
	background: var(--input-bgd-color);
	border: var(--border);
}

.settings-email-verification-inputs .MuiFilledInput-underline:before, 
.settings-email-verification-inputs .MuiFilledInput-underline:after,
.settings-email-verification-inputs .MuiFilledInput-root:hover,
.settings-email-verification-inputs .MuiFilledInput-underline:hover:before,
.settings-email-verification-inputs .MuiFilledInput-underline:hover:after	{
	border: 0;
	/* background-color: rgba(0, 0, 0, 0.09) */
}


.settings-email-verification-alert.MuiTypography-body1 {
	margin-top: 5px;
}

@media screen and (max-width: 840px) {
	.settings-email-verification-inputs.MuiFormControl-fullWidth {
		width: 90%;
	}

	.settings-email-verification-content-main .MuiDialogActions-root {
		width: 100%;
		padding: 0;
	}

	.setting-email-verification-buttons--width.MuiButton-contained.Mui-disabled {
		width: 100%;
	}
}