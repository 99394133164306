.allMessages {
	display: flex;
	/* flex: 1 1 auto; */
  overflow-y: auto;
	flex-direction: column;
	/* height: 480px; */
	position: absolute;
	width: 100%;
	height: 100%;
}

.loaderStart {
	display: flex;
	justify-content: center;
  cursor: pointer;
}

.messages-box--chat-bottom-bar-input-send-icon {
	color: var(--white-color);
}

.MuiFormControl-root.MuiTextField-root.messages-box--chat-bottom-bar-input.MuiFormControl-fullWidth {
  padding: 10px 0;
  margin: 0;
}

.chat-main-no-messages-text {
	display: flex;
	flex: 1 1 auto;
	justify-content: center;
	align-items: center;
}

.typingShow {
  height: 16px;
  color: #aaabb0;
  text-align: center;
  margin-top: 5px;
}

.loader {
  color: #aaabb0;
  font-size: 12px;
  padding: 5px 0 0;
  text-align: center;
  margin-top: 10px !important;
}

.scan-popup .MuiDialog-paperScrollPaper {
  flex: 1 1 auto;
  max-width: 600px;
  width: 100%;
  height: 500px;
	display: flex;
  align-items: center;
  padding: 0 50px 30px 50px;
  border: var(--border);
  border-radius: var(--border-radius-box);
}

.scan-popup-text h2 {
  font-size: var(--subtitle);
  color: var(--title-strong-color);
}

.scan-popup-content.MuiDialogContent-root {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.scan-popup-content-wrap {
  flex: 1 1 auto;
  border: var(--border-thicker);
  border-radius: var(--border-scan-popup);
  padding: 50px;
  overflow: hidden;
  overflow-y: auto;
}

.scan-popup-form-wrap.MuiGrid-item {
  margin-bottom: 20px;
}

.scan-popup-form-step {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--secondary-color);
  margin-right: 5px;
  top: -5px;
  border-radius: 50%;
  border: 2px solid var(--secondary-color);
}

.scan-popup-form-step span {
  position: relative;
  font-weight: 900;
  top: 1.5px;
}

.scan-popup-form-textfield {
  flex: 1 1 0;
}

.scan-popup-form-label.MuiFormLabel-root {
  font-size: var(--subtitle);
  color: var(--primary-color);
}

.scan-popup-form-input.MuiInputBase-root {
  font-size: var(--subtitle);
  color: var(--title-color);
  margin: 0;
}

.scan-popup-form-input.MuiInputBase-root input::placeholder, 
.scan-popup-form-input.MuiInputBase-root input::-webkit-placeholder {
  opacity: 1;
}

.scan-popup-buttons.MuiDialogContent-root {
  flex: 0 0 auto;
}

.chat-manage-template-content.MuiDialogContent-root{
  width: 100%;
  overflow-y: unset;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-manage-templates-content-header-text {
  display: flex;
  justify-content: center;
  color: var(--title-color);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.chat-manage-template-button {
  margin-block-start: auto;
}

.chat-manage-templates-card-media {
  width: 100%;
  height: 100%;
}

.chat-card-main-div {
  width: 100%;
  cursor: pointer;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-card {
  width: 70px;
  height: 60px;
}

.chat-order-now-dialog .MuiDialog-paperScrollPaper{
  width: 800px;
  height: 300px;
  max-width: 800px;
}

.chat-order-now-title {
  color: var(--title-color);
  display: flex;
  justify-content: center;
}

.chat-order-now-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-order-now-msg .MuiInputBase-input,
.chat-order-now-msg .MuiInput-underline:after,
.chat-order-now-msg .MuiInput-underline:before,
.chat-order-now-msg .MuiInput-underline:hover:not(.Mui-disabled):before {
  color: var(--title-color);
  border: unset;
}

.chat-order-now-checkbox.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--primary-color);
}

.chat-order-now-checkbox.MuiCheckbox-root {
  color: var(--title-color);
}

.chat-popup .MuiDialog-paperScrollPaper {
  flex: 1 1 auto;
  max-width: 600px;
  width: 100%;
  height: 500px;
	display: flex;
  align-items: center;
  padding: 0 50px 30px 50px;
  border: var(--border);
  border-radius: var(--border-radius-box);
  overflow-y: unset;
}

.chat-qrcode-wrapper-num {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--secondary-color);
  border-radius: 50%;
  margin-right: 10px;
}

.chat-qrcode-num.MuiTypography-body1 {
  font-size: 20px;
  color:	var(--secondary-color);
  font-weight: 900;
}

.chat-qrcode-circle.MuiSvgIcon-root{
  color: var(--primary-color);
	width: 30px;
  height: 30px;
  margin-right: 10px;
}

.chat-manage-templates-tabs .PrivateTabIndicator-colorPrimary-341 {
	background-color: var(--primary-color);
}

.chat-manage-templates-tab.MuiTab-textColorPrimary.Mui-selected {
  color: var(--primary-color);
}