@font-face {
	font-family: 'AvenirLTStd-Roman';
	src: local('AvenirLTStd-Roman'), url('../assets/fonts/AvenirLTStd-Roman.woff') format('woff');
}

/* --- CSS variables ---*/
:root {
  /* dimenstions */
  --height-of-header: 68px;
  
  /* Font props */
  --font-family: AvenirLTStd-Roman;
  --header-text: 40px;
  --subtitle-large: 25px;
  --subtitle: 20px;
  --subtitle-small: 15px;
  --very-small-text: 10px;

	/* Colors */
	--primary-color: #cf5ada;
	--secondary-color: #c8c8c8;
/* ------------------------------- */
	--title-color: #969696;
	--title-strong-color: #646464; 
	--primary-color-rgb: 207, 90, 218;
	--success-color: #47c43d;
	--success-color-rgb: 71, 196, 61;
	--circles-border-color: #fc0505;
	--white-color: #ffffff;
	--input-bgd-color: #f0f0f0;
	--options-dropdown-bgd-rgb: rgb(232, 231, 231);
	--backdrop-bgd: rgba(0, 0, 0, .5);
	--brown-gray-color: #b4b4b4;

  /* Border-line around box */
  --border: 1px solid var(--primary-color);
  --border-layout: 1px solid var(--brown-gray-color);
  --border-thicker: 3px solid var(--primary-color);
  --border-radius-box: 10px; 
  --border-radius-content: 12px;
  --border-radius-button: 10px;
  --border-scan-popup: 21px;

	/* UI props */
	--gap-y-of-layout: 40px;
	--box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);

	/* okx vars */
	--okx-padding: 32px;
	--okx-padding-large: 48px;
	--okx-padding-small: 16px;
	--okx-padding-tiny: 8px;
	--okx-margin: 32px;
	--okx-margin-large: 48px;
	--okx-margin-small: 16px;
  --okx-margin-tiny: 8px;

  /* mui sizes of columns vars*/
  --grid-xs-1: 8.333333%;
  --grid-xs-2: 33.333333%;
	--grid-xs-3: 25%;
	--grid-xs-6: 50%;
	--grid-xs-7: 58.333333%;
	--grid-xs-8: 66.666667%;
	--grid-xs-9: 75%;
	--grid-xs-10: 83.333333%;
	--grid-xs-12: 100%;

  /* mui breakpoint vars*/
  --xs-screen: 0px;
  --sm-screen: 600px;
  --md-screen: 960px;
  --lg-screen: 1280px;
  --xl-screen: 1920px;
}
