.map-searchbox-input {
	margin-left: 20px;
	margin-top: 10px;
	border-radius: 9px;
	box-shadow: none;
	background-color: var(--input-bgd-color);
	border: var(--border);
	padding: 4px 10px;
	outline: none;
	color: var(--title-color);
	width: 150px;
	height: 20px;
}

.map-searchbox-marker.MuiSvgIcon-root {
	width: 54px;
	height: 55px;
	color: var(--primary-color);
	position: "absolute";
  /* top: 100%;
  left: 50%; */
  transform: translate(-50%, -100%);
}