.sonar{
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  position: absolute;
  z-index: 3;
  width: 220px;
  left: -82px;
  top: -50px;
}

.oval{
  position: absolute;
  z-index: 4;
  left: -35px;
}
.wraper{
  position: relative;
  left: -24px;
}
.titleMain{
  margin: 0;
  font-size: 50px;
  color: #fff;
  /* letter-spacing: -1.4px; */
  font-weight: bold;
  font-family: "Open Sans";
}
.titleSub{
  font-size: 18px;
  color: #fff;
  letter-spacing: -1.2px;
  font-family: "Open Sans";
}
.titleWraper{
  margin-top: 250px;
}

@-moz-keyframes spiner { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spiner { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spiner { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.load-layout {
  background: none;
  border: none
}

.loader {
  width: 100%;
  height: 220px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--subtitle);
  color: var(--title-color);
}

.loader-logo {
  position: absolute;
  width: 66px;
  z-index: 5;
}

.loader-oval {
  position: absolute;
  width: 127px;
  height: 127px;
  z-index: 4;
}

.loader-sonar {
  position: absolute;
  width: 220px;
  animation: spiner 4s linear infinite;
  z-index: 3;
}

.loader-text {
  width: 100%;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100%;
  margin-top: 20px;
}

.loader-text p:first-child {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
}

.loader-text p:last-child {
  font-size: 18px;
  margin: 0;
}
