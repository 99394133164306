.welcome-layout > div > .flex-cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.welcome-dialog-btn.MuiButton-root {
	border-radius: 12px; 
	color: var(--title-strong-color);
	border-color: var(--primary-color); 
	width: 150px; 
	height: 40px;
}

.welcome-dialog-btn span {
	position: absolute; 
	font-size: 25px;
	font-weight: 400;
}

.welcome-logo {
	margin: 20px auto;
	width: 80%;
	max-width: 322px;
	height: auto;
}

.welcome-message {
	font-size: 95px;
	color: var(--secondary-color);
}

.welcome-terms {
	font-size: 25px;
	color: var(--title-strong-color);
	cursor: pointer;
}

.welcome-terms:hover {
	text-decoration: underline;
}

.welcome-button.MuiButton-root {
	width: 80%;
	min-width: 300px;
	max-width: 400px;
	height: 60px;
	font-size: 29px;
	color: var(--white-color);
	background-color: var(--primary-color);
	box-shadow: var(--box-shadow);
	border-radius: 12px;
	text-transform: none;
}

.welcome-button.MuiButton-root:hover {
	background-color: var(--primary-color);
}

.iconCss.MuiButton-iconSizeMedium {
	font-size: 50px;
	padding-left: 40px;
}

@media screen and (max-width: 480px) {
	.welcome-message {
		font-size: 65px;
	}
	.welcome-terms.MuiTypography-body1 {
		font-size: 18px;
	}

	.welcome-button.MuiButton-root {
		font-size: 15px;
	}
}
