.layout {
	position: relative;
	flex: 1 1 auto;
	height: auto;
	border-radius: 18px;
	background-color: var(--white-color);
	border: var(--border-layout);
	width: auto;
	padding: var(--okx-padding-small);
	margin: 20px 30px;
	overflow: hidden;
	overflow-y: auto;
}

.layout.layout--transparent {
	background-color: transparent;
	border: none;
}
