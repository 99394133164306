.settings-container.MuiGrid-grid-xs-5 {
  flex-grow: 0;
  max-width: calc(41.666667% + 8.333333% / 1.5);
  flex-basis: calc(41.666667% + 8.333333% / 1.5);
  align-items: stretch;
}

.settings-container--blank.MuiGrid-grid-xs-1 {
  flex-grow: 0;
  max-width: calc(8.333333% / 3.5);
  flex-basis: calc(8.333333% / 3.5);
}

.settings-wrap--gapper.MuiGrid-item {
  margin: 10px 0;
}

.settings-wrap-title.MuiTypography-body1 {
  flex: 0 0 auto;
  font-size: var(--subtitle);
  color: var(--title-color);
  text-align: left;
  margin-bottom: 4px;
}

.settings-wrap-box {
  flex: 1 1 auto;
  border: var(--border);
}

.settings-item.settings-item--stats.MuiGrid-item {
  padding: 10px 30px 10px 80px;
}

.settings-wrap-box--map {
  height: 300px;
}

.settings-wrap-box--distance {
  padding: 20px;
  border: var(--border);
}

.settings-wrap-box--agents {
  padding-top: 10px;
}

/* .settings-wrap-box--agents .MuiGrid-grid-xs-5 {
  min-width: 170px;
} */

.settings-wrap-box.settings-wrap-box--distance p {
  font-size: var(--subtitle);
  margin-left: 0;
  margin-bottom: 20px;
}

.settings-wrap-box p {
  font-size: 1.4rem;
  color: var(--title-color);
  margin-left: 20px;
}

.settings-distance-input .MuiFilledInput-root {
  width: 100px;
  padding: 10px;
  margin: 0 10px;
  border-radius: var(--border-radius-button);
  border: var(--border);
  
}

.settings-distance-input .MuiFilledInput-underline:before, .settings-distance-input .MuiFilledInput-underline:after, .settings-distance-input .MuiFilledInput-underline:hover:before {
  border: 0px
}

.settings-distance-input .MuiInputBase-input {
  color: var(--primary-color);
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 0;
}

.settings-distance-input .MuiFilledInput-input {
  padding: 0;
}

.settings-wrap-of-item {
  position: relative;
  cursor: pointer;
}

.settings-wrap-of-item--agents.MuiGrid-container.MuiGrid-item {
  padding-left: 10%;
  margin-top: 10px;
}

.settings-wrap-of-item--agents.MuiListItem-gutters {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  padding-left: 8rem;
}

.settings-wrap-of-item--agents li {
  display: flex;
  padding: 0;
}

.settings-wrap-of-item--agents li p {
  font-size: 25px;
  margin: 0;
}

.settings-wrap-box--agents p {
  font-size: 1rem;
  margin: auto;
}

.settings-wrap-box--agents svg {
  width: 24px;
  height: 24px;
}

.settings-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.25em 1.25em 1.25em 2.5em;
  margin: 0;
}

.settings-items--app {
  padding: 1.5em 1.25em 1.5em 2.5em;
}

.settings-item.MuiGrid-item {
  display: flex;
  align-items: center;
  padding: 30px 30px 30px 80px;
}

.settings-item svg {
  position: relative;
  font-size: 26px;
  color: var(--primary-color);
  margin-right: 20px;
}

.settings-item p {
  font-size: var(--subtitle-large);
  color: var(--secondary-color);
  line-height: 1;
}

.settings-item-border-line {
  width: 100%;
  color: var(--brown-gray-color);
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.settings-item-border-line--agents.MuiDivider-root {
  width: 95%;
  background: var(--brown-gray-color);
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  margin-top: 17px;
  margin-bottom: 20px;
}

.settings-item-btn.MuiButton-root {
  position: absolute;
  padding: 6px 11px;
  border-radius: var(--border-radius-button);
  border: 1px solid var(--brown-gray-color);
  right: 107px;
  line-height: 1;
  box-shadow: none;
}

.settings-item-btn.MuiButton-containedPrimary, .settings-item-btn.MuiButton-containedPrimary:hover, .settings-item-btn.MuiButton-containedPrimary:active {
  font-size: 1rem;
  text-transform: inherit;
  background: var(--white-color);
  color: var(--primary-color);
  box-shadow: none;
}

.settings-item-btn span.MuiButton-endIcon {
  margin-left: 18px;
}

.settings-item-btn span.MuiButton-endIcon.MuiButton-iconSizeMedium::after {
  position: absolute;
  content: '';
  height: 100%;
  top: 0;
  border-left: 1px solid var(--brown-gray-color);
  z-index: 10;
}

.settings-wrap-content {
  flex: 1 1 auto;
  width: 100%;
}

.settings-wrap-content p {
  font-size: 1rem;
}

.settings-wrap-rectangle {
  width: 100%;
  max-width: 355px;
  min-width: 170px;
  height: 87px;
  background: var(--input-bgd-color);
  outline: var(--border);
  overflow: hidden;
  overflow-y: auto;
}

.settings-wrap-rectangle > ul.MuiList-padding {
  padding: 0;
}

.settings-wrap-rectangle small {
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--title-color);
  margin-top: 5px;
  top: 0;
  background: var(--input-bgd-color);
  z-index: 1;
}

.settings-wrap-rectangle.MuiList-padding {
  padding: 0;
}

.settings-wrap-rectangle ul li {
  justify-content: center;
  font-size: var(--subtitle);
  padding: 0 ;
  padding-bottom: 5px;
}

.settings-wrap-rectangle ul li:first-child {
  padding-top: 5px;
}

.settings-wrap-rectangle ul li:last-child {
  padding-bottom: 0;
}

.settings-agents-button.MuiButton-root, .settings-agents-button.MuiButton-root:hover, .settings-agents-button.MuiButton-root:active {
  /* width: 237px; */
  height: 28px;
  font-size: 1rem;
  background: var(--primary-color);
  color: var(--white-color);
  padding: 0 40px;
  text-transform: capitalize;
  border-radius: var(--border-radius-button);
}

.settings-divider.MuiDivider-root {
  width: 100%;
  margin: 10px 0;
  border-bottom: 1px solid var(--brown-gray-color);
}

.settings-main-buttons.MuiButton-containedPrimary, .settings-main-buttons.MuiButton-containedPrimary, .settings-main-buttons.MuiButton-containedPrimary:hover, .settings-main-buttons.MuiButton-containedPrimary:hover {
  display: flex;
  flex-direction: column;
	background-color: var(--primary-color);
  box-shadow: var(--box-shadow);
	border-radius: var(--border-radius-content);
  font-size: 25px;
  text-transform: none;
  margin: 5px;
  padding-top: 14px;
}

.settings-main-buttons--width {
	width: 150px;
	height: 40px;
}

.settings-dialog .MuiBackdrop-root {
	background-color: transparent;
}

.settings-dialog .MuiDialog-paperScrollPaper {
	flex: 1 1 auto;
	border-radius: 18px;
	height: calc(100vh - 108px);
	display: flex;
	align-items: center;
}

.settings-dialog .MuiDialog-paperWidthSm {
	flex: 1 1 auto;
	max-width: 2500px;
	margin: 0;
	box-shadow: none;
	border: 1px solid var(--brown-gray-color);
}

.settings-dialog .MuiDialog-container {
  padding: 15px 0;
  margin: 20px 30px;
}

.settings-dialog-text .MuiTypography-h6 {
	position: relative;
	width: 80vw;
	text-align: center;
	font-size: 30px;
	font-weight: normal;
	line-height: 1.4;
	color: var(--title-strong-color);
}

.settings-dialog-content-header-text.MuiTypography-body1 {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: var(--title-strong-color);
}

.settings-dialog-blank--half.MuiGrid-grid-xs-1 {
	flex-grow: 0;
	max-width: calc(8.333333% / 2);
	flex-basis: calc(8.333333% / 2);
}

.settigs-agents-main-button {
  padding: 0px 5px;
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-column-gap: 5px;
  grid-template-rows: 35px;
}

.settings {
  display: flex;
  height: 100%;
  justify-content: center;
}

.settings-agent-select {
  cursor: pointer;
  color: var(--title-strong-color);
}

.settings-suspend-agent-select {
  color: var(--title-strong-color);
}

.settings-agent-selected {
  color: var(--primary-color);
}

.settings-qrcode-true.MuiButton-sizeSmall {
  width: 100%;
  background-color: green;
}

.settings-qrcode-false.MuiButton-sizeSmall {
  width: 100%;
  background-color: red;
}

.settings-agents-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


/* --------------- Settings for responsive --------------- */
@media screen and (max-width: 1650px) {
  .settings-wrap-box.settings-wrap-box--distance > div > div > .MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: var(--grid-xs-8);
    flex-basis: var(--grid-xs-8);
  }

  .settings-wrap-box.settings-wrap-box--distance > div > div > .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: var(--grid-xs-3);
    flex-basis: var(--grid-xs-3);
  }
}

@media screen and (max-width: 1590px) {
  .settings-item-btn.MuiButton-root {
    position: relative;
    margin-top: 10px;
    right: 0;
  }

  .settings-wrap-of-item--agents.MuiGrid-container.MuiGrid-item {
    padding-left: 5%;
  }

  .settings-item.MuiGrid-item, .settings-item.settings-item--stats.MuiGrid-item {
    padding-left: 50px;
  }
}

@media screen and (max-width: 1150px) {
  .settings-wrap-box.settings-wrap-box--distance > div > div > .MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: var(--grid-xs-12);
    flex-basis: var(--grid-xs-12);
  }

  .settings-wrap-box.settings-wrap-box--distance > div > div > .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: var(--grid-xs-2);
    flex-basis: var(--grid-xs-2);
  }
}

@media screen and (max-width: 960px) {
  .settings-wrap-box p:not(.settings-wrap-box--agents p) {
    margin-top: 10px;
    margin-left: 0;
  }

  .settings-item p {
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .settings-wrap-box--agents small {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .settings-wrap-box.settings-wrap-box--distance > div > div > .MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: unset;
    flex-basis: unset;
  }

  .settings-wrap-box.settings-wrap-box--distance > div > div > .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: min-content;
    flex-basis: auto;
  }

  .settings-wrap-box.settings-wrap-box--agents .settings-container--blank.MuiGrid-grid-xs-1 {
    flex-grow: 0;
    max-width: unset;
    flex-basis: unset;
    margin-top: 10px;
  }

  .settings-wrap-box.settings-wrap-box--agents > .settings-wrap-content > .MuiGrid-grid-xs-5 {
    max-width: var(--grid-xs-10);
    flex-basis: var(--grid-xs-10);
  }
}

@media screen and (max-width: 650px) {
  .settings {
    height: auto;
    flex-direction: column;
  }

  .settings-container.MuiGrid-grid-xs-5 {
    max-width: var(--grid-xs-12);
    flex-basis: var(--grid-xs-12);
  }

  .settings-container--blank.MuiGrid-grid-xs-1 {
    margin-top: 10px;
  }

  .settings-wrap-box.settings-wrap-box--agents > .settings-wrap-content > .MuiGrid-grid-xs-5 {
    max-width: var(--grid-xs-10);
    flex-basis: var(--grid-xs-10);
  }
}

.link-style.MuiTypography-colorPrimary {
  color: var(--secondary-color);
}

/* --------------- End Settings of responsive --------------- */