.preview-promotion-bordered--gapped.MuiGrid-item {
	min-height: 755px;
	padding: 5px 5px 0 5px;
	margin: 0 5px;
}

.preview-order-item-header.MuiTypography-body1 {
	flex: 0 0 auto;
	color: var( --secondary-color);
	align-self: start;
	font-size: 15px;
	line-height: 1.4;
	padding-left: 15px;
	margin-top: 10px;
}

.preview-order-item-logo img {
	width: 25px;
	height: 37px;
	border: var(--border);
	margin-right: 20px;
	margin-block-start: 5px;
	margin-block-end: 5px;
	object-fit: cover;
}

.preview-order-item-first-list-view.MuiGrid-grid-xs-12 {
	max-height: 50px;
	display: flex;
	border: var(--border);
	background-color: #fff;
	justify-content: space-around;
	padding: 0 0 0 20px;
}

.preview-order-item-first-list-view p {
	font-size: 20px;
	color: var(--title-color);
}

.preview-order-item-second-list-view {
	flex: 0 0 auto;
	height: 200px;
	background-color: #fff;
	border: var(--border);
	padding: 10px;
}

.preview-order-item-second-list-view-title.MuiTypography-body1, .preview-order-item-second-list-view-price.MuiTypography-body1, .preview-order-item-second-list-view-desc.MuiTypography-body1 {
	display: flex;
	justify-content: center;
	font-size: 25px;
	line-height: 1;
	color: #969696;
}

.preview-order-item-second-list-view-price.MuiTypography-body1 {
	position: relative;
	font-size: 30px;
}

.preview-order-item-second-list-view-price p {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
}

.preview-order-item-second-list-view-price span {
	margin-left: 7px;
	text-transform: uppercase;
}

.preview-order-item-second-list-view-desc.MuiTypography-body1 {
	font-size: 20px;
	margin-bottom: 20px;
}

.preview-order-item-second-list-small-topic {
	width: 60px;
	height: 90px;
	display: flex;
	border: var(--border);
}

.preview-order-item-photos-img--avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-order-item-second-list-small-topic--middle {
	border-right: 0;
	border-left: 0;
}

.preview-order-item-second-list-img img {
	width: 90px;
	height: 90px;
	border: var(--border);
}

.preview-order-item-second-list-view-pics-grid {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
}

.preview-order-item-second-list-view-pics-grid {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
}

.preview-order-item-box-first.MuiGrid-item {
	flex: 0 0 auto;
	height: 25px;
	margin-bottom: 20px;
}

.preview-order-item-box--second.MuiGrid-item {
	flex: 1 1 auto;
}

@media screen and (max-width: 1510px) {
	.preview-order-item-second-list-view {
		height: auto;
		padding: 15px;
	}
}

@media screen and (max-width: 960px) {
	.preview-order-item-second-list-view {
		height: auto;
		padding: 10px;
	}

	.preview-order-item-second-list-view-price.MuiTypography-body1 {
		font-size: 20px;
	}

	.preview-order-item-second-list-img img {
    width: 70px;
    height: auto;
	}

	.preview-order-item-second-list-view-price span {
		margin-left: 7px;
	}
}