.settings-about-card.MuiPaper-elevation1 {
  width: 264.1px;
  height: 262.2px;
  margin-bottom: 80px;
	box-shadow: unset;
}

.settings-about-card-media {
  width: 100%;
  height: 100%;
}

.settings-about-text.MuiTypography-body1{
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	color: var(--title-strong-color);
}

.settings-about-link.MuiTypography-colorPrimary {
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	color: var(--primary-color);
}

.settings-about-main-text-grid {
	width: 500px;
  height: 270px;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.setting-about-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
}

.setting-about-content.MuiDialogContent-root {
  flex: 0 0 auto;
}

@media screen and (max-width: 840px) {
  .settings-about-card {
    margin-top: 20px;
  }

  .setting-about-content.MuiDialogContent-root {
    padding: 0 10px;
  }

  .settings-about-text.MuiTypography-body1, .settings-about-link.MuiTypography-colorPrimary {
    text-align: center;
    font-size: 20px;
  }
}