.wrapper-content.MuiGrid-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  z-index: 0;
}

.wrapper-content.wrapper-content--load.MuiGrid-container {
  width: 100%;
  height: 100%;
  background-image: url('../../pages/load/images/background.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
