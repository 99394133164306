.settings-auto-replay-content-main.MuiDialogContent-root {
	border: var(--border);
	border-radius: var(--border-radius-button);
	width: 600px;
  height: 300px;
	box-shadow: var(--box-shadow);
	background-color: #fff;
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.settings-auto-replay-buttons--width {
  width: 400px;
  height: 40px;
}

.settings-auto-replay-buttons--width.MuiButton-contained.Mui-disabled {
	background-color: rgba(207, 90, 218, .5);
	color: #fff;
}

.settings-auto-replay-input .MuiFilledInput-root,
.settings-auto-replay-input .MuiFilledInput-underline:after,
.settings-auto-replay-input.MuiFilledInput-root:hover,
.settings-auto-replay-input .MuiFilledInput-underline:hover:before,
.settings-auto-replay-input .MuiFilledInput-underline:hover:after	{
	border: 0;
	/* width: 450px; */
  background-color: var(--input-bgd-color);
}

.settings-auto-replay-input .MuiFilledInput-underline:before {
	border: 0;
	height: 0;
}


.settings-auto-replay--border .MuiFilledInput-multiline,
.settings-auto-replay--border .MuiFilledInput-multiline:hover {
	border: 1px solid var(--primary-color);
	padding-top: 1px;
	height: 130px;
}

.settings-auto-replay--border .MuiFilledInput-inputMultiline {
	height: 100px;
	font-size: 25px;
	color: var(--title-strong-color);
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
}

@media screen and (max-width: 840px) {
	.settings-auto-replay-content-main.MuiDialogContent-root {
		width: 80%;
	}

	.settings-auto-replay-buttons--width.MuiButton-contained.Mui-disabled {
		width: 100%;
	}

	/* .settings-auto-replay-buttons--width.MuiButton-contained.Mui-disabled .MuiButton-label {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	} */
}