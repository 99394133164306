@import './vars.css';

/* --- MUI reset --- */

.MuiFormControl-root {
	margin-top: 20px;
}

.MuiButton-root, .MuiTypography-body1 {
	font-family: var(--font-family) !important;
}

.MuiButton-containedPrimary {
	background-color: var(--primary-color);
}

/* -- end MUI reset */

html, body {
	margin: 0;
	padding: 0;
	height: 100vh;
	font-family: var(--font-family);
	box-sizing: border-box;
}

#app {
	display: block;
	height: 100vh;
	position: relative;
	background-color: var(--input-bgd-color);
}

#app, .MuiDialog-root {
	min-width: 420px;
}

p {
	margin-top: 0;
	margin-bottom: 0;
}

ul, li {
	padding: 0;
	margin: 0;
	list-style: none;
}

/* --- Global classes --- */

.pov, .relative, .poo {
	position: relative;
}

.MuiButton-root, .MuiTypography-body1, .MuiFormLabel-root {
  font-family: var(--font-family) !important;
  /* line-height: 1 !important; */
}

.lefted {
	text-align: left;
}

.centered {
	text-align: center;
}

.righted {
	text-align: right;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.no-padding {
	padding: 0;
}

.padded {
	padding: var(--okx-padding);
}

.tiny-padded, .padded-tiny {
	padding: var(--okx-padding-tiny);
}

.v-padded, .padded-v {
	padding: var(--okx-padding) 0;
}

.tiny-v-padded, .padded-v-tiny {
	padding: var(--okx-padding-tiny) 0;
}

.h-padded, .padded-h {
	padding: 0 var(--okx-padding);
}

.top-padded, .padded-top {
	padding-top: var(--okx-padding);
}

.bottom-padded, .padded-bottom {
	padding-bottom: var(--okx-padding);
}

.left-padded, .padded-left {
	padding-left: var(--okx-padding);
}

.right-padded, .padded-right {
	padding-right: var(--okx-padding);
}

.no-margin {
	margin: 0!important;
}

.margined {
	margin: var(--okx-margin);
}

.v-margined, .margined-v {
	margin: var(--okx-margin) 0;
}

.h-margined, .margined-h {
	margin: 0 var(--okx-margin);
}

.top-margined, .margined-top {
	margin-top: var(--okx-margin);
}

.bottom-margined, .margined-bottom {
	margin-bottom: var(--okx-margin);
}

.left-margined, .margined-left {
	margin-left: var(--okx-margin);
}

.right-margined, .margined-right {
	margin-right: var(--okx-margin);
}

.flex-rows, .flex-cols {
	display: flex;
	justify-content: space-between;
}

.flex-rows .flex-cell, .flex-cols .flex-cell {
	flex: 1 1 auto;
}

.flex-rows .flex-cell-max, .flex-cols .flex-cell-max {
	flex: 1 0 100%;
}

.flex-rows .flex-cell-min, .flex-cols .flex-cell-min {
	flex: 0 1 0;
}

.flex-cols {
	flex-direction: row;
}

.flex-rows {
	flex-direction: column;
}

.no-wrap, .nowrap, .MuiGrid-container.no-wrap, .MuiGrid-container.nowrap {
	flex-wrap: nowrap;
}

.space-between {
	justify-content: space-between;
}

.abs-content, .absolute-content {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.layout > .abs-content, .layout > .absolute-content {
	left: var(--okx-padding-small);
	right: var(--okx-padding-small);
	top: var(--okx-padding-small);
	bottom: var(--okx-padding-small);
}

.color-primary {
	color: var(--primary-color);
}

.bg-primary {
	background-color: var(--primary-color);
}

.text-heading-big {
	font-size: 55px;
}

.text-heading {
	font-size: 40px;
}

.text-subheading {
	font-size: 29px;
}

.text-big {
	font-size: 25px;
}

.overflow-hidden {
	overflow: hidden;
}

.scroll-y {
	overflow-y: auto;
}

.shadow {
	box-shadow: var(--box-shadow);
}

.border-radius {
	border-radius: var(--border-radius-button);
}

.tiny-button {
	width: 150px;
	height: 30px;
	background-color: var(--primary-color);
	border-radius: var(--border-radius-button);
	box-shadow: var(--box-shadow);
	font-size: 20px;
	text-transform: none;
}

.link {
	text-decoration: underline;
}