.promos-backdrop {
  position: fixed;
  background: var(--backdrop-bgd);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease-out;
  z-index: 5;
}

.promos-backdrop--visible {
  opacity: 1;
  pointer-events: auto;
}

.promos-backdrop--hidden {
  opacity: 0;
  pointer-events: none;
}

.promos-item {
  flex: 1 1 auto;
}

.promoButton.MuiButton-root {
  background-color: var(--primary-color);
  border-radius: 12px;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);
  text-transform: none;
  width: 150px;
  height: 30px;
  position: relative;
}

.promoButton .MuiButton-label {
  position: absolute;
  font-size: 18px;
}

.promos-wrapper {
  padding: 25px 40px 25px 25px;
}

.promos-wrapper--preview {
  min-height: 695px;
  padding: 25px 0 25px 0;
}

/* .promos-wrapper--preview > div {
  height: 100%;
  height: -webkit-fill-available;
  height: -moz-fill-available;
  height: fill-available;
} */

.promos-box--title.MuiGrid-item {
  /* flex: 0 1 0; */
  flex: none;
  display: flex;
  justify-content: center;
  color: var(--secondary-color);
  margin-bottom: 4px;
}

.promos-box--title p {
  font-size: var(--subtitle);
}

.promos-box--bordered {
  flex: 1 1 0;
  /* min-height: 755px; */
  background: var(--input-bgd-color);
  border: var(--border);
}

.promotion-main-buttons.MuiButton-containedPrimary,
.promotion-main-buttons.MuiButton-containedPrimary:hover {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  width: 150px;
  text-transform: none;
  margin: 5px;
}

/* --------------- Settings for responsive --------------- */
@media screen and (max-width: 1400px) {
  .promos-layout > .flex-cols {
    flex-direction: column;
  }

  .promos-wrapper.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-8 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }

  .promotions-wrapper.MuiGrid-grid-xs-5 {
    max-width: 467px;
  }

  .promos-wrapper--preview.MuiGrid-container.MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: var(--grid-xs-7);
    flex-basis: var(--grid-xs-7);
  }
  
  .promos-wrapper--preview, .promos-wrapper {
    padding: 25px;
  }

  .promos-wrapper {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 960px) {
  .promotions-wrapper {
    min-width: 380px;
  }
}

@media screen and (max-width: 900px) {
  .preview-promotion-item-second-list-view {
    height: 280px;
  }
}

@media screen and (max-width: 600px) {
  .promotions-wrapper.MuiGrid-grid-xs-5,
  .promos-wrapper--preview.MuiGrid-container.MuiGrid-grid-xs-9,
  .promos-wrapper--preview.MuiGrid-container.MuiGrid-grid-xs-4,
  .promotion-edit-item.MuiGrid-container.MuiGrid-grid-xs-7,
  .promotion-edit-item.MuiGrid-container.MuiGrid-grid-xs-2,
  .order-item-holder-under.MuiGrid-container.MuiGrid-grid-xs-7,
  .promotion-edit-buttons-holder.MuiGrid-container.MuiGrid-grid-xs-2 {
    max-width: var(--grid-xs-12);
    flex-basis: var(--grid-xs-12);
  }

  .promotions-wrapper.MuiGrid-grid-xs-5:last-child {
    margin-top: 25px;
  }

  .promotions-wrapper {
    min-width: var(--grid-xs-12) !important;
  }

  .promotions-button-main-div {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)) !important;
  }

  .promotion-edit-item.MuiGrid-container.MuiGrid-grid-xs-2 > div,
  .promotion-edit-buttons-holder.MuiGrid-container.MuiGrid-grid-xs-2 > div {
    align-items: flex-start;
  }
}
/* --------------- End Settings for responsive --------------- */