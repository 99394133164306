.mainContent{
  text-align: center;
  margin-top: 50px;
}
.message{
  width: 100%;
  font-size: 14px;
  color: #000;
  font-family: Lato;
  margin-top: 6px;
}
.avatar{
  width: 42px;
  height: 42px;
  max-width: 42px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  /*margin-left: auto;*/
  /*position: absolute;*/
}
.wraper{
  position: relative;
  border: 1px solid red;
  height: 100px;
  margin-bottom: 10px;
  background-color: gainsboro;
}
.rightBubble{
  right: 50px;
  /*position: absolute;*/
  margin-left: -15px;
  margin-top: 0px;
}
.leftBubble{
  left: 50px;
  /*position: absolute;*/
  margin-left: 15px;
  margin-top: 0px;
	justify-content: flex-end;
}
.mdl-cell{
  margin: 0px !important;
}

.timeWraper.MuiGrid-grid-xs-12{
  color: var(--title-color);
	font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	margin-left: 15px;
}

.timeWraper--mine-msg.MuiGrid-grid-xs-12 {
  color: var(--title-color);
	font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	margin-right: 15px;
	justify-content: flex-end;
	display: flex;
}

.emoticon{
  max-width: 22px;
}
.oneLine.MuiTypography-body1{
	font-size: 20px;
  min-height: 22px;
	font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  margin-bottom: 0px;
	flex: 1 1 auto;
	color: var(--title-color);
}
.attachment{
  max-width: 100px;
  max-height: 100px;
}
.smallStatus{
  color: var(--title-color);
  font-size: 10px;
	display: flex;
	font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	justify-content: flex-end;
}
.day{
/*
  color: #fff;
  font-size: 13px;
  background-color: #765b77;
  font-weight: bold;
  width: 100%;
*/
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #bc76bf;
  font-weight: 300;
  width: 100%;
  padding: 4px;
  border-radius: 4px;
}
.messageItem {
  border-top: 1px dotted gainsboro;
  margin-bottom: 0px;
  padding: 0px !important;
}

.chat-messages-show-day {
	justify-content: center;
}

.chat-user-avatar {
	width: 55px;
	height: 55px;
	border-radius: 50%;
}

.chat-messages.MuiGrid-grid-xs-11 {
	align-content: center;
	margin-left: 15px;
  flex: 0 0 auto;
}

.chat-user-avatar-main.MuiGrid-grid-xs-1 {
	flex: 0 0 auto;
	width: 55px;
	margin-left: 15px;
}

.chat-message-main {
	border-bottom: 1px solid var(--input-bgd-color);
  flex-direction: row;
}

.chat-messages--mine-msg{
	justify-content: flex-end;
	text-align: end;
}

.chat-message-main--mine-msg {
	border-bottom: 1px solid var(--input-bgd-color);
	justify-content: space-evenly;
}