.layout{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 30px;
  margin-left: 30px;
  height: calc(100vh - 130px);
}

.formHolder {
  margin: 30px auto;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #eaeaea;
  width: 50%;
}

.MuiGrid-item.form-label {
  width: 360px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-top: 10px;
  padding-top: 4px;
  margin-right: 70px;
}

.MuiGrid-item.form-label div img {
  display: flex;
  align-items: center;
}

.MuiButton-containedSizeSmall.form-button {
  position: relative;
  margin-top: 40px;
}

.wrapper-num {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #c8c8c8;
  border-radius: 50%;
}

.wrapper-num .num {
  height: 15px;
  color: #c8c8c8;
  font-weight: 900;
}

.step-name {
  color: #cf5ada; 
  font-size: 20px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.label{
  font-size: 15px;
  color: #6b6b6b;
  margin-bottom: 6px;
}
.info{
  font-size: 13px;
  color: #c8c8c8;
  position: relative;
  /* margin-top: 20px;
  top: -20px; */
}
/* .number{
  position: absolute;
  width: 25px;
  height: 25px;
  font-size: 18px;
  color: #b1b1b1;
  left:6px;
  top: 1px; */
  /* line-height: 20px; */
/* } */
.circle.MuiSvgIcon-root{
  position: relative;
  color: #cf5ada;
  width: 29px;
  height: 29px;
  display: flex;
}
.wraper{
  position: absolute;
}
.separator{
  border-bottom: 1px solid #ebebeb;
}
.age{
  font-size: 14px;
  color: #a6a6a6;
  margin-right: 26px;
}
.selectedIcon{
  max-width: 52px;
}
.iconContainer{
  position: absolute;
  top: -5px;
  left: -3px;
  width: 60px;
}
.iconWraper{
  position: relative;
}
.iconError{
  top: 11px;
  left: -6px;
  font-size: 13px;
  line-height: 12px;
  color: rgb(244, 67, 54);
  /*transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;*/
  position: absolute;
}
.iconInfoWraper{
  display: inline-block;
  width: 300px;
  position: relative;
}

.agent-login-wrapper {
  position: relative;
  max-width: 600px;
  height: auto;
  padding: 10px 0 15px 0;
  border-radius: var(--border-radius-content);
  border: 2px solid var(--primary-color);
}

.agent-login-input-holder {
  padding-top: 20px;
}

.login-agent-main-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}