.icon-list-modal {
  width: 1070px;
  height: 300px;
  background: var(--input-bgd-color);
  border: var(--border);
  margin: 0 30px;
}

.icon-list-image {
  max-height: 100px;
	background-color: #fff;
	margin: 8px;
}

.icon-list-image--selected {
  background: var(--primary-color);
}

@media screen and (max-width: 840px) {
  .icon-list-modal {
    width: unset;
    margin: 0;
  }
}