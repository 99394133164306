.settings-manage-templates-header-links {

}

.settings-manage-templates-paper {
	width: 72vw;
}

.settings-manage-templates-paper.MuiPaper-elevation1 {
	box-shadow: none;
}

.settings-manage-templates-dialog-text	.MuiTypography-h6{
	position: relative;
	width: 80vw;
	text-align: center;
	font-size: 30px;
	font-weight: normal;
	line-height: 1.4;
	color: var(--title-strong-color);
	margin: 10px 0;
}

.settings-manage-templates-tabs .MuiTabs-indicator {
	width: 40vw;
	background-color: var(--primary-color);
	height: 3px;
}

.settings-manage-templates-tab.MuiTab-root,
.settings-manage-templates-tab.MuiTab-textColorPrimary.Mui-selected {
	max-width: 40vw;
	width: 40vw;
	font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
	letter-spacing: normal;
	text-transform: capitalize;
	color: var(--title-strong-color)
}

.settings-manage-templates-content.MuiDialogContent-root {
  width: 72vw;
	padding: 0;
  height: 750px;
  border-radius: var(--border-radius-box);
  box-shadow: var(--box-shadow);
  border: var(--border);
  background-color: var(--white-color);
	margin: 30px 0;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.settings-manage-templates-content-header-text.MuiTypography-body1 {
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
	color: var(--title-strong-color);
	margin: 5px 0;
}

.settings-dialog-title-divider--width.MuiDivider-root {
	width: calc(100% - 40px);
	height: 2px;
	background-color: var(--brown-gray-color);
}

.setting-manage-templates-buttons--width {
	width: 400px;
  height: 40px;
}

.settings-manage-templates-list-item.MuiListItem-divider {
	width: 75vw;
	border-bottom: 0.5px solid var(--brown-gray-color);
	padding: 0px;
}

.settings-manage-templates-list-item-main {
	justify-content: center;
}

.settings-manage-templates-list-item-text.MuiTypography-body1	{
	padding-left: 0;
	border: 0;
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	color: var(--title-strong-color);
	overflow-wrap: anywhere;
	flex: 1 1 auto;
}

.settings-manage-templates-list-item-buttons.MuiCardActions-root {
	padding: 0;
}

.settings-manage-templates-card-media	{
	height: 100px;
	width: 100px
}

.settings-manage-templates-card-text {
	flex: 1 1 auto;
	text-align: center;
}

.settings-manage-templates-list-main.MuiList-padding {
	padding: 0px;
	flex: 1 1 auto;
	overflow-x: hidden;
	overflow-y: auto;
	width: calc(100% - 40px);
}

.settings-manage-templates-list-item--padding.MuiListItem-divider {
	padding: 10px 0;
}

.settings-manage-templates-canned-answer .MuiDialog-paperWidthSm {
  width: 1200px;
  height: 310px;
  /* border: 0.5px solid var(--primary-color);
  background-color: var(--input-bgd-color); */
	max-width: 1200px;
	/* justify-content: center; */
	align-items: center;
}

.settings-manage-templates-canned-answer-dialog-text .MuiTypography-h6	{
	font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: var(--title-strong-color);
}

.settings-manage-templates-canned-answer-input .MuiFilledInput-multiline	{
	width: 1100px;
	height: 180px;
}

.settings-manage-templates-buttons--width.MuiButton-contained.Mui-disabled {
	background-color: rgba(207, 90, 218, .5);
	color: var(--white-color);
}

.settings-manage-templates-buttons--width {
	width: 150px;
	height: 40px;
}

.settings-manage-templates-canned-answer-content.MuiDialogContent-root {
	width: 1100px;
	height: 180px;
	padding-top: 0px;
	overflow-y: unset;
}

.settings-manage-templates-canned-answer-content .MuiFilledInput-input {
	width: 1100px;
	height: 170px;
}

.settings-manage-templates-canned-answer-content .MuiFilledInput-multiline {
	padding-left: 10px;
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	color: var(--title-strong-color);
	border: 0.7px solid var(--primary-color)
}

.settings-manage-templates-canned-answer-content .MuiInputBase-multiline {
	padding-left: 10;
}

.settings-manage-templates-canned-answer-content .MuiFilledInput-root:hover {
	background-color: var(--input-bgd-color);
}

.settings-manage-templates-canned-answer-content .MuiFilledInput-underline:hover:before,
.settings-manage-templates-canned-answer-content .MuiFilledInput-underline:after {
	border-bottom: unset;
}

.settings-manage-temlplate-canned-answer-dialog-action.MuiDialogActions-spacing > :not(:first-child) {
	margin: 5px;
}

.settings-manage-templates-max-letters.MuiTypography-body1 {
	flex: 1 1 auto;
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	color: var(--title-color);
}

.settings-manage-templates-small-buttons.MuiIconButton-root {
	color: var(--brown-gray-color);
}

.settings-manage-templates-small-buttons.MuiIconButton-root:hover {
	background-color: unset;
	color: var(--primary-color);
}

.settings-manage-templates-delete .MuiDialog-paperWidthSm {
	max-width: 700px;
	width: 700px;
  height: 200px;
  border-radius: var(--border-radius-box);
  box-shadow: var(--box-shadow);
  border: var(--border);
  background-color: var(--white-color);
	align-items: center;
}

.settings-manage-templates-canned-answer-dialog-text--font .MuiTypography-h6 {
	font-size: 25px;
}

@media screen and (max-width: 840px) {
	.settings-manage-templates-tab.MuiTab-root, .settings-manage-templates-tab.MuiTab-textColorPrimary.Mui-selected {
		font-size: 1rem;
	}

	.settings-main-buttons.MuiButton-containedPrimary, .settings-main-buttons.MuiButton-containedPrimary, .settings-main-buttons.MuiButton-containedPrimary:hover, .settings-main-buttons.MuiButton-containedPrimary:hover {
		width: auto;
		height: auto;
		font-size: var(--subtitle-small);
	}
}