.messages-box-items.MuiGrid-grid-xs-8 {
  max-width: unset;
  display: grid !important;
  grid-auto-flow: column;
  justify-items: flex-start;
}

.messages-box-items--right.MuiGrid-grid-xs-4 {
  max-width: unset;
  display: grid !important;
}

.messages-box-items--right div:last-child {
  display: flex;
}

.messages-box--users {
  flex: 0 0 auto;
  display: grid;
  grid-template-rows: min-content;
  padding: 5px 0;
  min-height: 250px;
  max-height: 262px;
  overflow: hidden;
  overflow-y: auto;
}

.messages-box--users-avatar {
  position: relative;
  width: 55px;
  height: 55px;
}

.messages-box--users-avatar-badge {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--very-small-text);
  background: var(--circles-border-color);
  color: var(--white-color);
  border-radius: 50%;
  top: 0;
  right: 0;
}

.messages-box--users-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.messages-box--users-name {
  justify-self: flex-start;
}

.messages-box--users-status {
  display: flex;
  align-items: center;
}

.messages-box--users-bullet {
  font-size: 1.3em;
  margin-right: 5px;
}

.messages-box--users-bullet--green {
  color: var(--success-color);
}

.messages-box--users-bullet--red {
  color: var(--circles-border-color);
}

.messages-box--users-avatar-img {
  border-radius: 50%;
}

.messages-user-profile-main.MuiGrid-container {
  height: max-content;
  display: grid;
  grid-auto-flow: column;
  padding: 2px 15px;
  cursor: pointer;
}

.messages-user-profile-main--border-off {
  border-bottom: 1px solid var(--brown-gray-color);
}

.messages-user-profile-main--border-off:last-child {
  border-bottom: 0;
}

.messages-user-profile-main--border.MuiGrid-container {
  border: 2px solid var(--primary-color);
  box-shadow: var(--box-shadow);
}

@media screen and (min-width: 1860px) {
  .messages-box-items {
    grid-template-columns: 110px repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: 63px;
  }
}

@media screen and (max-width: 1859px) {
  .messages-box-items {
    grid-template-columns: 110px repeat(auto-fit, minmax(120px, 1fr));
    grid-template-rows: 63px;
  }
}

@media screen and (max-width: 960px) {
  .messages-box-items.MuiGrid-grid-xs-8 {
    grid-auto-flow: unset;
  }

  .messages-box--users {
    max-height: 190px;
  }
  
  .messages-box-items {
    grid-template-rows: repeat(2, 1fr);
  }

  .messages-box--users-avatar {
    grid-row: span 2;
  }
}

@media screen and (max-width: 960px) {
  .messages-box--users {
    max-height: 220px;
  }
}
@media screen and (max-width: 600px) {
  .messages-box--chat-bottom-bar-options {
    padding: 10px 5px;
  }

  .messages-box--chat-bottom-bar-options > div.MuiGrid-grid-xs-4:first-child {
    max-width: var(--grid-xs-12);
    flex-basis: var(--grid-xs-12);
    margin-bottom: 10px;
  }

  .messages-box--chat-bottom-bar-options > div.MuiGrid-grid-xs-4:nth-child(2) .MuiGrid-justify-xs-center {
    justify-content: unset;
  }

  .messages-box--chat-bottom-bar-options > div.MuiGrid-grid-xs-4 {
    max-width: var(--grid-xs-6);
    flex-basis: var(--grid-xs-6);
  }

  .messages-box--chat-bottom-bar-options > div.MuiGrid-grid-xs-4:first-child .MuiIconButton-root {
    padding: 0;
  }
}