.setting-membership-dialog-content.MuiDialogContent-root {
	flex: unset;
	width: 600px;
	height: 400px;
	box-shadow: var(--box-shadow);
	border-radius: var(--border-radius-content);
	background-color: #fff;
	border: var(--border);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.setting-membership-dialog-content-circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #cc5dd9;
  background-color: #f0f0f0;
  width: 215px;
  height: 215px;
}

.setting-membership-dialog-content-circle-text.MuiTypography-body1 {
  font-size: 45px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color:  var(--primary-color);
}

.setting-membership-dialog-footer-text.MuiTypography-body1 {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--title-color);
}

.settings-dialog-title-divider.MuiDivider-root {
  width: 72vw;
  background-color: var(--primary-color);
  margin: auto;
}

@media screen and (max-width: 840px) {
  .setting-membership-dialog-content.MuiDialogContent-root {
    width: 80%;
    padding: 8px 15px;
  }

  .popup-content-xs {
    width: 80% !important;
    padding: 8px 4px !important;
  }

  .settings-dialog-text .MuiTypography-h6 {
    width: 100vw;
  }

  .setting-membership-dialog-content.MuiDialogContent-root .MuiDialogActions-root {
    width: 100%;
    width: -webkit-fill-available;
    justify-content: center;
    padding: 0;
  }

  .settings-dialog-text.MuiDialogTitle-root, .settings-manage-templates-dialog-text.MuiDialogTitle-root {
    padding: 0;
  }

  
}