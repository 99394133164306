.register-logo {
  display: block;
  width: 226px;
  height: auto;
  margin:auto;
  padding-top: 100px;
}

.register-layout .text-subheading {
  padding-bottom: 10px;
}

.register-select.MuiOutlinedInput-root {
  color: var(--primary-color);
  font-size: 24px;
  text-align: left;
  border-color: rgba(var(--primary-color-rgb), .40);
  border-radius: 0;
  width: 280px;
}

.register-select-icon {
  color: var(--secondary-color);
}

.register-input.MuiFormControl-root {
  font-size: 24px;
  color: var(--title-color);
  border: var(--border);
}

.register-btn.MuiButton-root {
  margin: 0;
  font-size: 24px;
}
/*
.MuiMenu-paper.MuiPopover-paper {
  width: 360px;
  height: 220px;
  border: solid 1px var(--primary-color);
  background-color: var(--white-color);
  bottom: 81px;
  top: 620px !important;
  left: 50% !important;
  transform: translateX(-50%) !important; 
  border-radius: 0;
}

.MuiMenu-paper.MuiPopover-paper ul li {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
*/
.regiter-country.MuiListItem-root {
  color: var(--brown-gray-color);
  font-size: 1rem;
  font-weight: normal;
}

.regiter-country.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: var(--primary-color);
}

.register-buttons.MuiButton-root {
  min-width: 350px;
  height: 60px;
  padding: 20px 10px;
}

.register-buttons.MuiButton-root:last-child {
  margin-bottom: 20px;
}

.register-buttons.MuiButton-root > .MuiButton-label {
  font-size: 25px;
}

/* .register-buttons.MuiButton-root {
  width: 80%;
  min-width: 300px;
  max-width: 400px;
  height: 60px;
  font-size: 29px;
  color: var(--white-color);
  background-color: var(--primary-color);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  text-transform: none;
} */

@media screen and (max-width: 600px) {
  .register-buttons.MuiButton-root {
    min-width: 325px;
  }
}