.promotion-edit-checkpoint {
  flex: 0 1 0;
}


.promotion-edit-checkpoint svg {
  position: relative;
  width: 29px;
  height: 29px;
  /* top: -5px; */
  margin-right: 5px;
}

.promotion-edit-checkpoint .MuiSvgIcon-root {
  font-size: 1.9rem;
}

.promotion-edit-wrap {
  flex: 1 1 auto;
}

.promotion-edit {
  flex:  1 1 auto;
}

.promotion-edit.MuiGrid-item {
  margin-top: 20px;
}

.promotion-edit-item {
  flex:  1 1 auto;
}

.promotion-edit-title.MuiGrid-item {
  flex: 0 0 auto;
  margin-bottom: 4px;
}

.promotion-edit-title p {
  font-size: var(--subtitle);
  color: var(--primary-color);
}

.promotion-edit-box {
  flex:  1 1 auto;
  padding: 10px 20px;
  border: var(--border-thicker);
}

.promotion-edit-container {
  margin-bottom: 6px;
}

.promotion-edit-container:last-child {
  margin-bottom: 0;
}

.promotion-edit-create-promotion-icon-wraper {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    place-items: flex-end;
    width: 25px;
    height: 25px;
    font-size: 18px ;
    background: var(--white-color);
    color: #B9B9B9;
    border: 2px solid #B9B9B9;
    margin-right: 5px;
}

.promotion-edit-create-promotion-icon-wraper--check-icon {
  width: 45px;
  height: 45px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  margin-right: 10px;
}

.promotion-edit-create-promotion-icon-wraper--check-image {
  width: 42px;
  height: 42px;
  border: var(--border);
  border-radius: 50%;
  margin-right: 34px;
}

.promotion-edit-check-icon {
  position: absolute;
  color: var(--primary-color);
}
.wraper{
  position: absolute;
}

.number{
  position: absolute;
  width: 25px;
  height: 25px;
  font-size: 18px;
  color: #b1b1b1;
  left:6px;
  top: 1px;
}

.promotionEditTitle .MuiInput-underline:before, .promotionEditTitle .MuiInput-underline:after {
  border-bottom: 0 !important;
}

.promotionEditTitle .MuiInputBase-root {
  font-size: 18px;
  font-weight: 400;
  color: var(--title-color);
}

.promotionEditTitle .MuiInputBase-input {
  padding: 0;
}

.icon-list-dialog .MuiDialog-paperWidthSm {
  max-width: 1200px;
  border-radius: 10px;
}

.icon-list-dialog-title {
  display: flex;
  justify-content: center;
  font-size: var(--subtitle);
  color: var(--title-color);
}

.icon-list-dialog-actions {
  display: flex;
  justify-content: flex-end;
  margin: 0 30px;
}

.promotion-edit-dialog-btn.MuiButton-containedPrimary {
  background: var(--primary-color);
  color: var(--white-color);
  font-size: 1.3em;
	border-radius: 12px;
  text-transform: none;
	width: 150px;
	height: 40px;
  margin: 5px;
  box-shadow: none;
}

.promotion-edit-dialog-btn--cancel.MuiButton-containedPrimary {
  background: transparent;
  color: var(--title-color);
  border: var(--border);
}

.promotion-edit-dialog-btn--cancel.MuiButton-containedPrimary:hover {
  background: transparent;
}

.promotion-edit-dialog-btn--choose.MuiButton-containedPrimary:hover {
  background: var(--primary-color);
}

.promotion-edit-date-time .MuiInputBase-root {
  color: var(--title-color)
}
.promotion-edit-date-time .MuiInput-underline:before, .promotion-edit-date-time .MuiInput-underline:after, 
.promotion-edit-date-time .MuiInput-underline:hover:not(.Mui-disabled):before {
  border: 0
}

.promotion-edit-date-time .MuiPickersToolbar-toolbar {
  background-color: var(--primary-color);
}

.promotion-edit-date-time .MuiPickersDay-daySelected {
  background-color: var(--primary-color);
}


.promotion-edit-switch .MuiSwitch-root {
  align-items: center;
}

.promotion-edit-switch .MuiSwitch-switchBase {
  top:-5px;
  left: -7px;
}

.promotion-edit-switch .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(70%);
}

.promotion-edit-switch .MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-switchBase {
  top:-5px;
  left: 3px;
}

.promotion-edit-switch .MuiSwitch-track {
  height: 50%;
  background-color: slategray;
}
.promotion-edit-switch .MuiSwitch-colorSecondary.Mui-checked {
  color: #94898f;
}
.promotion-edit-switch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #94898f;
}

.promotion-edit-switch .MuiSwitch-thumb {
  color: var(--title-color);
  width: 25px;
  height: 25px;
  box-shadow: var(--box-shadow);
}

.promotion-edit-switch .MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb {
  color: var(--success-color);
}

.promotion-edit-header-selection {
  flex: 1 1 0;
}

.promotion-edit-header-texts p:nth-child(2) {
  color: var(--title-color)
}

.promotion-edit-header-text.MuiTypography-body1 {
  color: var(--primary-color);
  font-size: var(--subtitle);
}

.promotion-edit-header-text-error.MuiTypography-body1, .promotion-edit-header-text-error .MuiFormHelperText-root {
  color: var(--circles-border-color);
  font-size: 0.75rem;
}

.MuiPickersCalendarHeader-transitionContainer > p {
  color: var(--primary-color);
}

.MuiPickersDatePickerRoot-toolbar, .MuiPickersDay-daySelected, .MuiPickersToolbar-toolbar, .MuiPickersClock-pin, .MuiPickersClockPointer-pointer, .MuiPickersClockPointer-noPoint {
  background: var(--primary-color) !important;
}

.MuiPickersDay-current, .MuiPickersClockNumber-clockNumber {
  color: var(--primary-color) !important;
}

.MuiPickersDay-daySelected.MuiPickersDay-current, .MuiPickersClockNumber-clockNumberSelected {
  color: var(--white-color) !important;
}

.MuiPickersClockPointer-thumb {
  border-color: var(--primary-color) !important;
}