.settings-blocked-clients-list-item--selected {
	color: var(--primary-color);
	border: var(--border);
	border-bottom: 0;
	box-shadow: var(--box-shadow);
}

.settings-blocked-clients-list-item.settings-blocked-clients-list-item--selected.MuiListItem-divider {
	border-bottom: var(--border);
	overflow: hidden;
}

.settings-blocked-clients-content.MuiDialogContent-root {
  width: 55vw;
	padding: 0;
  height: 800px;
  border-radius: var(--border-radius-box);
  box-shadow: var(--box-shadow);
  border: var(--border);
  background-color: #fff;
	margin: 5px 0 30px 0;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.settings-blocked-clients-content-header-wrapper.MuiGrid-container {
	width: 55vw;
	display: grid;
	grid-template-columns: 1.5fr 2fr;
	padding-left: 30px;
}

.settings-blocked-clients-content-header-text.MuiGrid-container {
	display: grid;
	grid-template-columns: 85px repeat(auto-fill, minmax(120px, 1fr));
	align-items: center;
}

.settings-blocked-clients-content-header-text--right.MuiGrid-container {
	grid-template-columns: 275px repeat(auto-fit, minmax(90px, 1fr));
}

.settings-blocked-clients-content-text.MuiTypography-body1 {
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
	color: var(--primary-color);
}

.settings-blocked-clients-card.MuiPaper-elevation1 {
	box-shadow: unset;
}

.settings-blocked-clients-card-media {
	height: 55px;
	width: 55px;
	border-radius: 50%;
	border: var(--border);
}

.settings-blocked-clients-list-item.MuiListItem-divider {
	width: calc(100% - 10px);
	display: grid;
	grid-template-columns: 1.5fr 2fr;
	border-bottom: 1px solid var(--brown-gray-color);
	padding: 5px 5px 5px 30px;
	margin: 0 5px;
	transition: all .2s ease-out;
}

.settings-blocked-clients-content-header-text	{
	font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	color: var(--title-color);
}

.settings-blocked-clients-content-header-text-main.MuiGrid-item	{
	display: grid;
	grid-template-columns: 85px repeat(auto-fill, minmax(120px, 1fr));
	align-items: center;
}

.settings-blocked-clients-content-header-text-main--right.MuiGrid-item	{
	display: grid;
	grid-template-columns: 275px repeat(auto-fit, minmax(90px, 1fr));
}

.settings-blocked-clients-content-header-text--cursor {
	cursor: pointer;
}

ul.MuiList-root.settings-manage-templates-list-main.settings-manage-templates-list-main--block-clients.MuiList-padding {
	width: 100%;
	padding: 5px 0;
}

@media screen and (max-width: 1440px) {
	.settings-blocked-clients-content.MuiDialogContent-root {
		width: 80vw;
	}

	.settings-blocked-clients-content-header-wrapper.MuiGrid-container {
		width: 80vw;
	}
}

@media screen and (max-width: 1060px) {
	.settings-blocked-clients-content-header-text--right.MuiGrid-container {
		grid-template-columns: 175px repeat(auto-fit, minmax(90px, 1fr));
	}

	.settings-blocked-clients-card.MuiPaper-elevation1 {
		grid-row: span 2;
	}

	.settings-blocked-clients-list-item.MuiListItem-divider, .settings-blocked-clients-content-header-wrapper.MuiGrid-container {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
}

@media screen and (max-width: 960px) {
	.settings-blocked-clients-content-header-text-main--right.MuiGrid-item {
		grid-template-columns: 175px repeat(auto-fit, minmax(90px, 1fr));
	}

	.settings-blocked-clients-content-header-text-main.MuiGrid-item {
		grid-template-columns: 85px repeat(auto-fit, minmax(120px, 1fr));
	}

	.settings-blocked-clients-list-item.MuiListItem-divider {
		padding: 0;
	}

	.settings-blocked-clients-list-item.MuiListItem-divider, .settings-blocked-clients-content-header-wrapper.MuiGrid-container {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
}