.order-item-holder-wrap, .order-item-holder-under {
	flex: 1 1 auto;
}

.order-item-holder-wrap.MuiGrid-item {
	background: var(--white-color);
	margin-top: 20px;
}

.order-item-edit-box {
	flex: 1 1 auto;
	padding: 40px 20px;
	border: var(--border-thicker);
	box-shadow: var(--box-shadow);
	border-radius: var(--border-radius);
	flex-direction: column;
}

.order-item-edit-box-content.MuiGrid-grid-xs-12 {
	flex: 1 1 auto;
}

.order-item-edit-box-content.MuiGrid-container {
	margin-bottom: 10px;
}

.order-item-edit-box-content.MuiGrid-container:last-child {
	margin-top: 15px;
	margin-bottom: 0;
}

.order-item-edit-checkpoint {
	flex: 0 1 0;
}

.order-item-edit-checkpoint svg {
	position: relative;
	width: 29px;
	height: 29px;
	margin-right: 5px;
}

.order-item-edit-checkpoint .MuiSvgIcon-root {
	font-size: 1.9rem;
}

.order-item-edit-checkpoint--invisible div {
	visibility: hidden;
}

.order-item-edit-selection {
	flex: 1 1 0;
}

.order-item-title {
	flex: 0 0 auto;
	height: 0;
}

.order-item-edit-title.MuiTypography-body1 {
	font-size: var(--subtitle);
	color: var(--primary-color);
	margin-bottom: 4px;
}

.order-item-title p.MuiTypography-body1 {
	color: var(--primary-color);
}

.order-item-checkbox.MuiFormControlLabel-root {
	margin-left: 0;
}

.order-item-checkbox .MuiSvgIcon-root {
	width: 26px;
  height: 26px;
  border-radius: 8px;
  background-color: var(--secondary-color);
}

.order-item-checkbox .MuiCheckbox-colorSecondary.Mui-checked.order-item-checkbox .MuiSvgIcon-root {
	background-color: var(--primary-color);
}

.order-item-checkbox .MuiCheckbox-root {
	color:	var(--secondary-color);
	padding: 2px;
}

.order-item-checkbox .MuiCheckbox-colorSecondary.Mui-checked {
	color: var(--primary-color);
}

.order-item-checkbox .MuiTypography-body1 {
	color: var(--secondary-color);
}

.order-item-edit-images .MuiBackdrop-root {
	background-color: transparent;
}

.order-item-edit-images .MuiDialog-paperScrollPaper {
	flex: 1 1 auto;
	height: calc(100vh - 108px);
	display: flex;
	align-items: center;
	border-radius: 18px;
}

.order-item-edit-images .MuiDialog-paperWidthSm {
	flex: 1 1 auto;
	max-width: 2400px;
	margin: 0;
	box-shadow: none;
	border: 1px solid var(--brown-gray-color);
}

.order-item-edit-images .MuiDialog-container {
  padding: 15px 0;
  margin: 20px 30px;
}

.order-item-edit-dialog-content.MuiDialogContent-root {
	flex: unset;
	width: 1200px;
	height: 440px;
	box-shadow: var(--box-shadow);
	border-radius: var(--border-radius-content);
	background-color: var(--white-color);
	border: var(--border);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.order-item-edit-dialog-content-inner {
	flex: 1 1 auto;
}

.order-item-edit-dialog-text .MuiTypography-h6 {
	position: relative;
	width: 80vw;
	text-align: center;
	font-size: 30px;
	font-weight: normal;
	line-height: 1.4;
	color: var(--title-strong-color);
}

.order-item-edit-dialog-back-btn.MuiSvgIcon-root {
	position: absolute;
	font-size: 1.1em;
	top: 0;
	left: 1em;
	cursor: pointer;
	color: var(--title-color);
}

.order-item-edit-dialog-text-border.MuiDivider-root {
	position: absolute;
	width: 72vw;
	left: 50%;
	border: 0;
	margin: 0;
	border-bottom: var(--border);
	transform: translate(-50%, -50%);
}

.order-item-edit-dialog-text p {
	color: var(--title-color);
	font-size: 20px;
	font-weight: normal;
	margin-top: 10px;
}

.order-item-edit-main-img.MuiGrid-grid-xs-2 {
	/* background-color:	var(--primary-color); */
	justify-content: center;
	align-items: center;
	width: 300px;
	height: auto;
	max-width: 300px;
	flex-basis: 300;
}

.order-item-edit-main-img p {
	display: block;
	font-size: 20px;
	color: var(--secondary-color);
	margin-top: 0;
	margin-bottom: 5px;
}

.order-item-edit-main-img--width.MuiGrid-grid-xs-2 {
	width: 200px;
}

.order-item-edit-photos-topic.order-item-edit-photos-topic--opacity {
	background: rgba(var(--primary-color-rgb), .7);
}

.order-item-edit-photos-topic {
	position: relative;
  height: 300px;
	width: 300px;
  display: flex;
  justify-content: center;
  background: rgba(var(--primary-color-rgb), 1);
  cursor: pointer;
}

/* .order-item-edit-photos-img { */
	/* position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover; */
/* } */

.order-item-edit-photos-img--cropped {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	-o-object-fit: cover;
	object-fit: cover;
}

/* .order-item-edit-photos-img--camera {
	position: absolute;
	width: unset;
	height: unset;
	top: 0;
	left: 0;
} */



/* .order-item-edit-invisible.MuiGrid-container {

} */

.order-item-edit-photos-topic--width {
  width: 200px;
}

.order-item-edit-box-logo-redirection {
	margin-top: 20px;
	cursor: pointer;
}

.promotion-edit-header-text--gray.MuiTypography-body1 {
	color: var(--secondary-color);
}

/* .promotion-main-buttons.MuiDialogActions-spacing > :not(:first-child) {
	margin-left: unset;
} */

.promotion-main-buttons-holder {
	flex: 1 1 auto;
}

.promotion-main-buttons-dialog {
	/* margin: 0 !important; */
	margin-left: 15px !important;
}

.order-items-buttons.MuiButton-containedPrimary, .order-items-buttons.MuiButton-containedPrimary, .order-items-buttons.MuiButton-containedPrimary:hover, .promotion-create-buttons.MuiButton-containedPrimary:hover {
	display: flex;
	flex-direction: column;
	background-color: var(--primary-color);
	box-shadow: var(--box-shadow);
	border-radius: 12px;
	width: 150px;
	font-size: 20px;
	height: 40px;
	text-transform: none;
	margin: 5px;
}

.order-items-buttons.MuiButton-containedPrimary, .order-items-buttons.MuiButton-containedPrimary:hover {
	height: 40px;
	font-size: 25px;
}

@media screen and (max-width: 840px) {
	.order-item-edit-dialog-content.MuiDialogContent-root {
		justify-content: unset;
		padding: 0 !important;
	}

	.order-item-edit-dialog-back-btn.MuiSvgIcon-root {
		position: relative;
		left: 0;
	}

	.order-item-edit-dialog-text.MuiDialogTitle-root {
		padding: 0;
	}

	.order-item-edit-main-img .MuiGrid-grid-xs-2 {
		max-width: var(--grid-xs-12);
		flex-basis: var(--grid-xs-12);
	}
}