.MuiGrid-root.settings-suspend-agent-content-grid.MuiGrid-item.MuiGrid-grid-xs-5 {
	flex-grow: 0;
	max-width: calc(41.666667% + calc(8.333333% / 4));
	flex-basis: calc(41.666667% + calc(8.333333% / 4));
}

.settings-dialog-blank--half.settings-dialog-blank--half--suspend-agent.MuiGrid-grid-xs-1 {
	flex-grow: 0;
	max-width: calc(8.333333% / 4);
	flex-basis: calc(8.333333% / 4);
}

.settings-dialog-text--suspend-agent.MuiDialogTitle-root {
  padding: 0;
}

.settings-suspend-agent-content.MuiDialogContent-root {
	width: 52vw;
  border-radius: var(--border-radius-box);
  box-shadow: var(--box-shadow);
  border: var(--border);
  background-color: var(--white-color);
	display: flex;
  padding: 20px 0;
  flex: 0 0 auto;
}

.settings-suspend-agent-content .MuiDialogContent-root:first-child, .settings-suspend-agent-content .MuiDialogContent-root {
	padding: 0;
	background-color: var(--input-bgd-color);
	/* width: 450px; */
  height: 500px;
	flex: unset;
	border: var(--border);
}

.settings-suspend-agent-inline-content {
	width: 450px;
  height: 500px;
	border: var(--border);
	background-color: var(--secondary-color);
}

.settings-suspend-agent-content-grid {
  justify-content: center;
	/* align-items: center;
	display: flex;
	flex-direction: column;
	width: 450px;
  height: 500px; */
}

.settings-suspend-agent-content-header-text.MuiTypography-body1 {
	color: var(--primary-color);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 10px;
}

.settings-suspend-agent-content-footer-text.MuiTypography-body1{
	margin-top: 10px;
	color: var(--title-color);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.settings-suspend-agent-list-item.MuiListItem-root {
	padding-top: 0;
	padding-bottom: 0;
}

.settings-suspend-agent-list-item-text .MuiTypography-body1 {
	color: var(--title-strong-color);
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	cursor: pointer;
}

.settings-suspend-agent-list-item-text--color .MuiTypography-body1{
	color: var(--primary-color);
}

.settings-dialog-blank--half--suspend-agent.MuiGrid-grid-xs-1 {
  flex-grow: 0;
  max-width: calc(8.333333% / 4);
  flex-basis: calc(8.333333% / 4);
}


button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.settings-main-buttons.settings-auto-replay-buttons--width.settings-suspend-agent-buttons.MuiButton-containedPrimary {
  width: 300px;
  margin: auto;
  margin-top: 5px;
}

@media screen and (max-width: 840px) {
  .settings-suspend-agent-content.MuiDialogContent-root {
    flex: 1 1 auto;
  }

  .MuiGrid-root.settings-suspend-agent-content-grid.MuiGrid-item.MuiGrid-grid-xs-5 {
    max-width: var(--grid-xs-12);
    flex-basis: var(--grid-xs-12);
  }

  .MuiGrid-root.settings-suspend-agent-content-grid.MuiGrid-item.MuiGrid-grid-xs-5:last-child {
    margin-top: 20px;
  }
}