.settings-invite-customers-link.MuiTypography-body1 {
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	color: var(--primary-color);
}

@media screen and (max-width: 840px) {
  .settings-invite-customers-link.MuiTypography-body1 {
    font-size: 1rem;
    text-align: center;
  }
}