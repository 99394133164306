.messages-wrapper--chat {
  flex: 1 1 auto;
}

.messages-wrapper--all-mess {
  flex: 0 0 auto;
}

.messages-wrapper-title {
  flex: 1 1 auto;
}

.messages-wrapper-content {
  flex: 0 0 auto;
}

.messages-wrapper--right {
  padding-left: 20px;
}

.messages-wrapper--right p {
  align-self: center;
}

.messages-wrapper p {
  flex: 0 0 auto;
  color: var(--title-color);
}

.messages-wrapper-title.MuiTypography-body1 {
  align-self: flex-start;
  font-size: var(--subtitle);
  margin-bottom: 4px;
}

.messages-wrapper-title--preview.MuiTypography-body1 {
  align-self: center;
}

.messages-box {
  flex: 1 1 auto;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
}

.messages-box--chat {
  position: relative;
  display: flex;
  flex-direction: column;
}

.messages-box--chat--resized {
  position: fixed;
  width: 95%;
  height: calc(100vh - 175px);
  background: white;
  bottom: 0;
  left: 50%;
  z-index: 7;
  transform: translate(-50%, -50px);
}

.message-box--chat-backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .4);
  top: 50%;
  left: 50%;
  z-index: 6;
  transform: translate(-50%, -50%);
  transition: opacity .3s ease-out;
}

.message-box--chat-backdrop--visible {
  opacity: 1;
  pointer-events: auto;
}

.message-box--chat-backdrop--hidden {
  opacity: 0;
  pointer-events: none;
}


.message-box--chat-resize-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white-color);
  border: var(--border);
  border-top: 0;
  border-right: 0;
  border-radius: var(--border-radius-button);
  cursor: pointer;
  z-index: 2;
}

/* .messages-box--chat-bottom-bar-options.messages-box--chat-scan-code.MuiSvgIcon-root {
  color: var(--primary-color);
} */
.message-box--chat-resize-icon svg {
  font-size: 2em;
  color: var(--secondary-color);
  transform: rotate(45deg);
  transition: .2s ease-out;
}

svg.MuiSvgIcon-root.messages-box--chat-scan-code, svg.MuiSvgIcon-root.messages-box--chat-scan-code:hover {
  color: var(--primary-color);
}

svg.messages-box--chat-scan-code--disabled {
  color: var(--secondary-color);
  pointer-events: none;
}

.message-box--chat-resize-icon svg:hover {
  color: var(--primary-color);
}

.messages-box--chat h3 {
  font-size: 1.9em;
  font-weight: normal;
  text-align: center;
  color: var(--secondary-color);
}

.messages-box--gray {
  background-color: var(--input-bgd-color);
}

.messages-wrapper-buttons.MuiGrid-item {
  flex: 0 0 auto;
  margin: 5px 0 0 0;
}

.messages-wrapper-buttons--chat.MuiGrid-item {
  flex: 0 0 auto;
  padding: 0 20px;
}

.messages-box--chat-typography.MuiTypography-body1 {
  margin-left: 5px;
}

.messages-wrapper-button.MuiButton-root {
  width: 150px;
  height: 20px;
  color: var(--white-color);
  font-size: 15px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  margin-right: 20px;
  border: 0;
  border-radius: 12px;
  box-shadow: none;
  line-height: 0;
  padding: unset;
}

.messages-wrapper-button.MuiButton-outlined.Mui-disabled {
  background-color: rgba(var(--primary-color-rgb), .6);
  color: var(--white-color);
}

.messages-wrapper-button.MuiButton-contained.Mui-disabled {
  background-color: rgba(var(--primary-color-rgb), .6);
  color: var(--white-color);
}

.messages-wrapper-buttons--chat.messages-wrapper-button.MuiButton-root {
  margin: 0;
}

.messages-wrapper-button.MuiButton-root:hover, .messages-wrapper-button.MuiButton-root:active {
  background-color: rgba(var(--primary-color-rgb), .6);
  box-shadow: none;
}

.messages-wrapper-button--green.MuiButton-root {
  background-color: rgba(var(--success-color-rgb), .5);
}

.messages-wrapper-button--green.MuiButton-root:hover, .messages-wrapper-button--green.MuiButton-root:active {
  background-color: rgba(var(--success-color-rgb), .5);
  box-shadow: none;
}

.messages-wrapper-spacer {
  height: 20px;
  margin-top: 5px;
}

.messages-box--chat-bottom-bar {
  width: 100%;
}

.messages-box--chat-bottom-bar-textfield {
  width: 100%;
  border-top: 1px solid rgba(var(--primary-color-rgb), 1);
  border-bottom: 1px solid rgba(var(--primary-color-rgb), 1);
}

.messages-box--chat-bottom-bar-input {
  width: 100%;
  height: 45px;
  background-color: var(--input-bgd-color);
}

.messages-box--chat-bottom-bar-input.MuiInput-root.MuiInputBase-fullWidth {
  height: 100%;
}

.messages-box--chat-bottom-bar-input .MuiFilledInput-input {
  height: 45px;
  padding: 0 20px;
}

.messages-box--chat-bottom-bar-input .MuiFilledInput-root, .messages-box--chat-bottom-bar-input .MuiFilledInput-root:hover {
  padding: 0 20px;
  background-color: transparent;
}

.messages-box--chat-bottom-bar-input .MuiFilledInput-underline:before,
.messages-box--chat-bottom-bar-input .MuiFilledInput-underline:after,
.messages-box--chat-bottom-bar-input .MuiFilledInput-underline:hover:before {
  height: 0;
  border: 0;
}

.messages-box--chat-bottom-bar-input-icon.MuiInputAdornment-root {
  color: var(--white-color);
  order: 1;
  right: 20px;
  margin-top: 0 !important;
  border-radius: 50%;
  cursor: pointer;
}

/* .messages-box--chat-bottom-bar-input-icon-circle.MuiButton-containedPrimary, .messages-box--chat-bottom-bar-input-icon-circle.MuiButton-containedPrimary:hover {
  width: 35px;
  min-width: auto;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  border-radius: 50%;
  padding: 0;
}

.messages-box--chat-bottom-bar-input-icon-circle span {
  width: auto;
}

.messages-box--chat-bottom-bar-input-icon-circle span .MuiButton-endIcon {
  margin: 0;
} */

.messages-box--chat-bottom-bar-input-icon-circle.MuiIconButton-colorPrimary, .messages-box--chat-bottom-bar-input-icon-circle.MuiIconButton-colorPrimary:hover {
  width: 35px;
  min-width: auto;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  border-radius: 50%;
  padding: 0;
}

.messages-box--chat-bottom-bar-input-icon-circle span {
  width: auto;
}

.messages-box--chat-bottom-bar-input-icon-circle span .MuiButton-endIcon {
  margin: 0;
}

.messages-box--chat-bottom-bar-options {
  padding: 10px 20px;
}

.messages-box--chat-bottom-bar-options .MuiSvgIcon-root {
  color: var(--secondary-color);
  transition: .2s ease-out;
  cursor: pointer;
}

.messages-box--chat-bottom-bar-options .MuiSvgIcon-root:hover {
  color: rgb(136, 136, 136);
}

.messages-box--chat-bottom-bar-options p, .messages-box--chat-bottom-bar-options label {
  color: var(--secondary-color);
}

.messages-box--chat-bottom-bar-options-item--checkbox .MuiSvgIcon-root, .messages-box--chat-bottom-bar-options-item--checkbox .MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--success-color);
}

.message-flex {
  display: flex;
}

/* --------------- Settings for responsive --------------- */

@media screen and (max-width: 1860px) {
  .messages-box-items {
    grid-template-columns: 95px repeat(auto-fill, minmax(150px, 1fr));
  }

  .messages-box-items--right {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media screen and (max-width: 1440px) {
  .message-flex {
    flex-direction: column;
    align-items: center;
  }

  .message-flex > .MuiGrid-grid-xs-8 {
    max-width: var(--grid-xs-10);
    flex-basis: var(--grid-xs-10);
  }

  .messages-box--gray {
    min-height: 750px;
  }

  .messages-wrapper--right {
    padding-left: 0;
  }

  .messages-wrapper--chat.MuiGrid-item {
    margin: 10px 0;
  }

  .messages-wrapper--right.MuiGrid-grid-xs-4 {
    max-width: var(--grid-xs-10);
    flex-basis: var(--grid-xs-10);
  }
}

@media screen and (max-width: 1200px) {
  .message-flex > .MuiGrid-grid-xs-8 {
    max-width: var(--grid-xs-12);
    flex-basis: var(--grid-xs-12);
  }

  .messages-wrapper--right.MuiGrid-grid-xs-4 {
    max-width: var(--grid-xs-12);
    flex-basis: var(--grid-xs-12);
  }
}

@media screen and (max-width: 960px) {
  .messages-box--chat--resized {
    width: 85%;
  }
  .messages-box--chat h3 {
    padding: 0 10px;
  }
}

@media screen and (max-width: 600px) {
  .messages-box-items {
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 10px;
  }

  .messages-box-items.MuiGrid-grid-xs-8 .messages-box--users-avatar {
    grid-row: span 3;
  }

  .messages-box--chat h3 {
    font-size: 1.3em;
  }

  .messages-wrapper-buttons--chat.MuiGrid-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    padding: 0;
    margin: 0;
    margin-top: 10px;
  }

  .messages-wrapper-button.MuiButton-outlined.Mui-disabled {
    margin: 0;
  }
}

.info {
  text-align: center;
  color: gray;
  font-size: 13px;
  background-color: #efefef;
  font-weight: 300;
  width: 100%;
  padding: 10px;
}

.messages-admin-permission {
  align-content: center;
  justify-content: center;
}

.messages-admin-permission-text.MuiTypography-body1 {
  font-size: 35px;
  color: var(--title-strong-color);
}
/* --------------- End Settings for responsive --------------- */