.promotions-header.MuiTypography-root {
	text-align: center;
	font-size: var(--subtitle);
	color: var(--secondary-color);
	margin-bottom: 4px;
}

.promotions-header-new.MuiTypography-root {
	color: var(--primary-color);
}

.promotions-list {
	display: flex;
	align-items: inherit;
	color: var(--secondary-color);
}

.promotions-list-title {
	color: var(--title-color);
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: 5px;
	overflow: hidden;
}

.promotions-list-title span {
	font-size: 20px;
}

.promotions-list-date {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	text-align: right;
}


.promotions-list-date span, .promotions-list-status.MuiTypography-body1 {
	font-size: var(--subtitle-small);
}

.promotions-title--selected {
	color: var(--primary-color);
}

.promotions-title--selected .promotions-list-title {
	color: var(--primary-color);
}

.promotions-first-row {
	position: relative;
	height: 300px;
	border: var(--border);
	overflow: hidden;
	/* border-bottom: 0; */
	/* padding: 0 10px 0 10px; */
}

.promotions-first-row--selected {
	border: var(--border-thicker);
	/* border-bottom: 0; */
}

.promotions-first-row--unselected {
	border: var(--border);
	/* border-bottom: 0; */
}

.promotions-second-row {
	border: var(--border);
	background-color: var(--input-bgd-color);
	max-height: 280px;
	overflow-y: auto;
	overflow-x: hidden;
	height: 280px;
}

.promotions-second-row--selected {
	border: var(--border-thicker);
}

.promotions-second-row--unselected {
	border: var(--border);
}


.promotions-open-button.MuiButton-containedPrimary {
	background-color: var(--primary-color);
	border-radius: 12px;
	width: 150px;
	padding: 5px 10px;
	line-height: initial;
	opacity: 0.5;
}

.promotions-open-button--select.MuiButton-containedPrimary {
	opacity: 1;
}

.promotions-create-button.MuiButton-containedPrimary {
	background-color: var(--primary-color);
	border-radius: 12px;
	width: 150px;
	padding: 5px 10px;
	line-height: inherit;
}

.promotions-create-button.MuiButton-containedPrimary:hover, .promotions-open-button.MuiButton-containedPrimary:hover {
	background-color: var(--primary-color);
}

.promotions-create-button--unselect.MuiButton-containedPrimary, .promotions-open-button--unselect.MuiButton-containedPrimary {
	opacity: 0.5;
}

.promotions-select-options.MuiList-root {
	position: absolute;
	width: 138px;
	display: flex;
	flex-direction: column;
	background: var(--white-color);
	padding: 0;
	margin: 0;
	border: var(--border-thicker);
	border-top: 0;
	border-left: 0;
	top: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s ease-out;
	z-index: 2;
}

.promotions-select-options.MuiList-padding {
	padding: 0;
}

.promotions-select-options--visible.MuiList-root {
	opacity: 1;
	pointer-events: auto;
}

.promotions-select-options--hidden.MuiList-root {
	opacity: 0;
	pointer-events: none;
}

.promotions-select-option.MuiListItem-root {
	text-align: left;
	color: var(--title-color);
	padding: 2px 5px;
	margin-bottom: 5px;
	transition: .3s ease-out;
	cursor: pointer;
}

.promotions-select-option.MuiListItem-root:last-child {
	margin-bottom: 0;
}

.promotions-select-option.MuiListItem-root:hover {
	background: rgba(236, 236, 236, .8);
}

.promotions-select-option--active.MuiListItem-root {
	font-weight: 600;
	color: var(--primary-color);
}

.promotions-select-option.MuiListItem-root:before {
	content: '>';
	margin-right: 5px;
	visibility: hidden;
}


.promotions-select-option.promotions-select-option--active.MuiListItem-root:before {
	content: '>';
	margin-right: 5px;
	visibility: visible;
}

.promotions-select-option--active.MuiListItem-root:hover {
	background: var(--options-dropdown-bgd-rgb);
}

.promotions-select-option.MuiListItem-root span.MuiTypography-body1 {
	font-size: 15px;
}

.promotions-wrapper {
	max-height: 400px;
	max-width: 467px;
	min-width: 310px;
}

.promotions-list-wrapper {
	height: 277px;
	padding: 0 10px;
	background: var(--input-bgd-color);
	overflow-y: auto;
	overflow-x: hidden;
}

.promotions-list-items {
	display: flex;
	flex-direction: column;
	flex: 1 0 100%;
}

.promotions-list-item.MuiListItem-root {
	padding: 0;
}

.promotions-list-header {
	border-bottom: var(--border);
	padding: 2px 10px;
}

.promotions-list-header--selected {
	border-bottom: var(--border-thicker);
}

.promotions-list-header--unselected {
	border-bottom: var(--border);
}

.promotions-list-header-title.MuiTypography-body1  {
	font-size: 15px;
	color: var(--title-color);
	cursor: pointer;
}

.promotions-list-header-title.MuiTypography-body1::before {
	content: '>';
	margin-right: 5px;
}

.promotion-button-wrapper {
	margin-top: 20px;
}

.promotion-main-buttons.MuiButton-containedPrimary, .promotion-create-buttons.MuiButton-containedPrimary, .promotion-main-buttons.MuiButton-containedPrimary:hover, .promotion-create-buttons.MuiButton-containedPrimary:hover {
  display: flex;
  flex-direction: column;
	background-color: var(--primary-color);
  box-shadow: var(--box-shadow);
	border-radius: 12px;
	width: 150px;
  font-size: 20px;
	height: 30px;
  text-transform: none;
  margin: 5px;
}

.promotion-main-buttons--publish.MuiButton-containedPrimary, .promotion-main-buttons--publish.MuiButton-containedPrimary:hover {
	background-color: rgba(var(--success-color-rgb), 1);
	color: var(--white-color);
}

.promotion-main-buttons.MuiButton-contained.Mui-disabled {
	background-color: rgba(var(--primary-color-rgb), .5);
	color: var(--white-color);
}

.promotion-main-buttons--publish.MuiButton-contained.Mui-disabled {
	background-color: rgba(var(--success-color-rgb), .5);
	color: var(--white-color);
}

.promotions-and-order-item-list-status {
	text-align: end;
}

.promotions-and-order-item-list-status span {
	font-size: var(--subtitle-small);
}

.promotions-and-order-item-header-main-text.MuiTypography-body1  {
	font-size: 15px;
	text-align: right;
	color: var(--title-color);
}

.promotions-and-order-item-header-main {
	align-self: center;
}

.promos-buttons .MuiButton-root {
	width: 100%;
	border-radius: var(--border-scan-popup);
}

.promos-buttons .MuiButton-containedSizeSmall {
	padding: 3px 20px;
}

.promotions-button-main-div {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
	grid-gap: 5px;
	margin-top: 10px;
}