.settings-dialog-text--create-agent.MuiDialogTitle-root {
  padding: 0;
}

.settings-create-agent-content.MuiDialogContent-root {
	width: 800px;
  min-height: 500px;
  border-radius: var(--border-radius-box);
  box-shadow: var(--box-shadow);
  border: var(--border);
  background-color: var(--white-color);
	display: flex;
	flex: none;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.settings-create-agent-inline-grid.MuiGrid-grid-xs-12{
	flex-direction: column;
  align-items: center;
	flex-basis: unset;
}

.settings-create-agent-title.MuiGrid-root {
  width: 50%;
  align-items: center;
  margin-right: 150px;
}

.settings-create-agent-title ~ .MuiFormControl-root {
  width: 50%;
}

.settings-create-agent-circle.MuiSvgIcon-root{
  color: var(--primary-color);
	width: 40px;
  height: 40px
}

.settings-create-agent-wrapper-num {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--secondary-color);
  border-radius: 50%;
}

.settings-create-agent-num.MuiTypography-body1 {
  position: absolute;
  font-size: 25px;
  color:	var(--secondary-color);
  font-weight: 900;
  top: 3px;
  left: 50%;
  transform: translateX(-50%);
}

.settings-create-agent-step-name.MuiTypography-body1 {
	font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
	color: var(--primary-color);
	margin-left: 35px;
}

.settings-create-agent-checkbox.MuiCheckbox-colorSecondary.Mui-checked, .settings-create-agent-checkbox .MuiSvgIcon-root {
	color: var(--primary-color);
	flex: 0 0 auto;
	width: 40px;
	height: 40px;
}

.settings-create-agent-checkbox-main-grid.MuiGrid-grid-xs-1 {
	flex: 0 0 auto;
}

.settings-create-agent-inline-grid-checkbox.MuiGrid-grid-xs-12 {
	flex-basis: unset;
	flex-direction: row;
  align-items: center;
  padding-left: 125px;
}

.settings-create-agent-checkbox-title.MuiGrid-grid-xs-11 {
	flex: 0 0 auto;
	align-items: center;
}

@media screen and (max-width: 840px) {
  .settings-create-agent-content.MuiDialogContent-root {
    height: auto;
  }

  .settings-create-agent-inline-grid-checkbox.MuiGrid-grid-xs-12 {
    padding-left: 0;
  }

  .settings-create-agent-title.MuiGrid-root, .settings-create-agent-title ~ .MuiFormControl-root {
    width: 100%;
    margin-top: 10px;
  }

  .settings-create-agent-title.MuiGrid-root {
    margin-right: 0;
  }

  .settings-create-agent-step-name.MuiTypography-body1 {
    margin-left: 15px;
  }

  .settings-create-agent-content .MuiDialogActions-root,
  .settings-main-buttons.MuiButton-containedPrimary, .settings-main-buttons.MuiButton-containedPrimary, .settings-main-buttons.MuiButton-containedPrimary:hover, .settings-main-buttons.MuiButton-containedPrimary:hover {
    width: 100%;
  }

  .settings-create-agent-content .MuiDialogActions-root {
    padding: 0;
  }

  .settings-create-agent-input.MuiFormControl-root {
    margin-left: 10px;
    margin-right: 10px;
  }

}